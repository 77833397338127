import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import set from 'lodash/set';

import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Divider, Form, Modal} from 'antd';

import CardWrapper from './CardWrapper';
import MenuItemComponent from './MenuItemComponent';
import MenuItemTitle from './MenuItemTitle';

import {PARENT_MAPPING_OPTIONS, REFETCH_MAPPING_REQUEST} from 'utils/constants';
import {generateMappingPayload, handleErrorFetch, moneyFormat, removeEmptyKeys} from 'utils/utils';
import {createMapping, editMapping} from 'utils/request/mapping';

import localization from 'localization';
import PreviewLabelModal from './PreviewLabelModal';
import {get, isEmpty} from 'lodash';
const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageMapping;

const ManageMapping = ({goBack, conditionConfig, previousPhase, menuData, mappingDetails, isEdit, hash,
	previewLabelRef, autoFillDummyRef, menuMaster}) => {
	const [form] = Form.useForm();
	const {posId} = useParams();

	const structureWatcher = Form.useWatch([], form);
	const isBundle = menuMaster?.tags?.includes('bundle') || menuData?.tags?.includes('bundle');

	const getMappingValue = () => {
		return form.getFieldsValue();
	};

	const {selectedParentMenu, selectedOptionGroupId, asComplexRule = [], asOptionGroup = []} = conditionConfig;
	const isNone = selectedParentMenu === PARENT_MAPPING_OPTIONS.NONE;

	const [complexRuleList, setComplexRuleList] = useState([]);
	const [optionGroupList, setOptionGroupList] = useState([]);

	const [loading, setLoading] = useState(false);

	const [previewLabelOpen, setPreviewLabelOpen] = useState(false);

	const openLabelPreview = () => setPreviewLabelOpen(true);

	const handleSubmit = async e => {
		try {
			setLoading(true);
			const payload = {
				menuMapping: isNone
					? {menu: {items: [], isParent: false}}
					: e,
			};

			payload.posId = posId;
			if (isEdit) payload.hash = hash;
			else payload.menu = {
				menuId: menuData.menuId,
				menuPrice: menuData.menuPrice,
				optionGroups: menuData.optionGroups.map(optionGroup => {
					return {
						optionGroupId: optionGroup.optionGroupId,
						minSelection: optionGroup.minSelection,
						maxSelection: optionGroup.maxSelection,
						menus: optionGroup.menus.map(menu => {
							return {
								menuId: menu.menuId,
								menuPrice: menu.menuPrice,
							};
						}),
					};
				}),
			};

			const fetchFn = isEdit ? editMapping : createMapping;

			if (selectedParentMenu !== PARENT_MAPPING_OPTIONS.MENU) {
				set(payload, ['menuMapping', 'menu'], {items: [], isParent: false});
			}

			const response = await fetchFn(removeEmptyKeys(payload));

			if (response.success) {
				if (!isEdit) {
					window.opener.postMessage(REFETCH_MAPPING_REQUEST);
					window.close();
				} else {
					goBack();
				}
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const getFormPayload = () => {
		const {
			formPayload,
			optionGroupList,
			complexRuleList,
		} = generateMappingPayload({
			mappingDetails,
			selectedParentMenu,
			selectedOptionGroupId,
			asComplexRule,
			asOptionGroup,
			isBundle,
		});

		setOptionGroupList(optionGroupList);
		setComplexRuleList(complexRuleList);
		form.setFieldsValue(formPayload);
	};

	const autoFillDummy = () => {
		const {
			formPayload,
		} = generateMappingPayload({
			mappingDetails : form.getFieldsValue(),
			selectedParentMenu,
			selectedOptionGroupId,
			asComplexRule,
			asOptionGroup,
			fillDummy: true,
			isBundle,
		});

		form.setFieldsValue(formPayload);
	};

	const calculateMainMenuPrice = (mainMenu, menuData) => {
		return mainMenu?.reduce?.((sum, item) => sum + Number.isInteger(item.menuPrice) ? item.menuPrice : menuData?.menuPrice, 0);
	};

	const getNSmallestPrices = (priceData, n = 0) => {
		const priceArray = Object.entries(priceData);

		// Custom sorting function to handle null values
		priceArray.sort((a, b) => {
			if (a[1] === null && b[1] === null) return 0;
			if (a[1] === null) return 1;
			if (b[1] === null) return -1;
			return a[1] - b[1];
		});

		return Object.fromEntries(priceArray.slice(0, n));
	};

	const sumChildMenuPrices = data => {
		const result = {};
		if (data && data.childMenus) {
			for (const [key, menuArray] of Object.entries(data.childMenus)) {
				const foundChildMenu = menuData.optionGroups.flatMap(group => group.menus).find(menu => menu.menuId == key);
				const sum = menuArray.reduce((total, item) => {
					if (item.menuPrice !== undefined && item.menuPrice !== null || foundChildMenu?.menuPrice) {
						return total + (item.menuPrice || foundChildMenu.menuPrice);
					}
					return total === null ? 0 : total;
				}, 0);
				result[key] = sum;
			}
		}
		return result;
	};

	const getCheapestOptionGroup = optionGroupData => {
		let output = 0;

		for (const optionGroupId in optionGroupData) {
			if (!isEmpty(optionGroupData[optionGroupId])) {
				const optionGroupInfo = menuData?.optionGroups?.find(el => el.optionGroupId == optionGroupId);
				const ogMinSelection = optionGroupInfo?.minSelection || 0;
				if (ogMinSelection > 0) {
					const ogPriceList = sumChildMenuPrices(optionGroupData[optionGroupId]);
					const requiredItems = getNSmallestPrices(ogPriceList, ogMinSelection);

					for (const requiredChild in requiredItems) {
						if (requiredItems[requiredChild] && Number(requiredItems[requiredChild])) output += requiredItems[requiredChild];
					}
				}
			}
		}

		return output;
	};

	const findLowestPriceLevelCartesian = complexRule => {
		let lowestSum = Infinity;
		let lowestLevel = null;
		function traverseAndSum(obj, currentPath = []) {
			if (Array.isArray(obj)) {
				// We've reached the deepest level with menu items
				const haveOverridePrice = obj.some(item => Number.isInteger(item.menuPrice));
				let sum = 0;
				if (haveOverridePrice) {
					sum = obj.reduce((acc, item) => acc + (item.menuPrice || 0), 0);
				} else {
					currentPath.forEach(childMenuId => {
						const foundChildMenu = menuData.optionGroups.flatMap(group => group.menus).find(menu => menu.menuId == childMenuId);
						sum += foundChildMenu?.menuPrice || 0;
					});
				}
				if (sum > 0 && sum < lowestSum) {
					lowestSum = sum;
					lowestLevel = currentPath[0]; // The top-level key
				}
			} else if (typeof obj === 'object' && obj !== null) {
				// We're still in nested objects, continue traversing
				for (const [key, value] of Object.entries(obj)) {
					traverseAndSum(value, [...currentPath, key]);
				}
			}
		}

		traverseAndSum(complexRule);

		return lowestSum !== Infinity ? {lowestLevel, lowestSum} : {lowestLevel: null, lowestSum: 0};
	};

	const calculateSubtotal = () => {
		let output = 0;

		const menuItems = get(structureWatcher, ['menu', 'items'], []);

		output += calculateMainMenuPrice(menuItems, menuData);

		const optionGroupItems = get(structureWatcher, ['optionGroups'], {});

		const cheapestOptionGroup = getCheapestOptionGroup(optionGroupItems);
		output += cheapestOptionGroup;

		const complexRuleItems = get(structureWatcher, ['complexMenu', 'items'], {});
		const {lowestSum} = findLowestPriceLevelCartesian(complexRuleItems);

		if (lowestSum) output += lowestSum;

		return output;
	};

	useEffect(() => {
		getFormPayload();
		previewLabelRef.current = openLabelPreview;
	}, [mappingDetails]);

	useEffect(() => {
		autoFillDummyRef.current = autoFillDummy;
	}, []);

	return (
		<>
			<PreviewLabelModal
				open={previewLabelOpen}
				close={() => setPreviewLabelOpen(false)}
				form={form}
				menuData={menuData}
			/>
			<Form
				scrollToFirstError
				form={form}
				layout="vertical"
				name="menuStructureMappingForm"
				onFinish={e => {
					Modal.confirm({
						icon: <ExclamationCircleOutlined />,
						title: locale.SaveConfirmationModal.title,
						content: locale.SaveConfirmationModal.content,
						cancelText: locale.SaveConfirmationModal.cancel,
						okText: locale.SaveConfirmationModal.ok,
						onOk: () => handleSubmit(e),
						centered: true,
					});
				}}
				requiredMark={false}
			>
				<div className='flex flex-col gap-6 pb-8'>
					{/* Menu Section */}
					<Form.Item name={PARENT_MAPPING_OPTIONS.MENU}>
						<CardWrapper
							selectedParentMenu={selectedParentMenu}
							isNone={isNone}
							previousPhase={previousPhase}
							type={PARENT_MAPPING_OPTIONS.MENU}
							title={locale.menu}>
							<MenuItemComponent
								showDivider={false}
								getMappingValue={getMappingValue}
								form={form}
								section={PARENT_MAPPING_OPTIONS.MENU}
								ignored={isNone || selectedParentMenu !== PARENT_MAPPING_OPTIONS.MENU}
								menuItem={menuData}
								isBundle={isBundle}
								isEdit={isEdit}
							/>
						</CardWrapper>
					</Form.Item>
					{/* End of Menu Section */}

					{/* Complex Rule Section */}
					{
						asComplexRule.length ? (
							<Form.Item name={PARENT_MAPPING_OPTIONS.COMPLEX_RULE}>
								<CardWrapper
									selectedParentMenu={selectedParentMenu}
									isNone={isNone}
									previousPhase={previousPhase}
									type={PARENT_MAPPING_OPTIONS.COMPLEX_RULE}
									title={locale.complexRule}>
									<div className='flex flex-col gap-4'>
										{
											complexRuleList.map((complexRule, index) => (
												<MenuItemComponent
													showDivider={index < complexRuleList.length - 1}
													getMappingValue={getMappingValue}
													form={form}
													ignored={isNone}
													key={index}
													section={PARENT_MAPPING_OPTIONS.COMPLEX_RULE}
													isComplex={true}
													menuItem={complexRule}
												/>
											))
										}
									</div>
								</CardWrapper>
							</Form.Item>
						) :
							null
					}
					{/* End of Complex Rule Section */}

					{/* Option Group Section */}
					{
						optionGroupList.length
							? (
								<Form.Item name={PARENT_MAPPING_OPTIONS.OPTION_GROUP}>
									<CardWrapper
										form={form}
										selectedParentMenu={selectedParentMenu}
										isNone={isNone}
										previousPhase={previousPhase}
										type={PARENT_MAPPING_OPTIONS.OPTION_GROUP}
										separatable={posId == 2}
										footer={() => (
											<div>{locale.subtotalCalculation.replace('{{subtotal}}', moneyFormat({value: calculateSubtotal()}))}</div>
										)}
										title={locale.optionGroup}>
										<div className='flex flex-col gap-4'>
											{
												optionGroupList.map((optionGroup, index) => (
													<>
														<div
															className='flex flex-col gap-4'
															key={optionGroup.optionGroupId}>
															<MenuItemTitle
																optionGroupList={optionGroupList}
																currentOptionGroupId={optionGroup.optionGroupId}
																currentOptionGroup={optionGroup}
																form={form}
																separatable={posId == 2}
															/>
															{
																optionGroup.menus.map((menu, menuIndex) => (
																	<MenuItemComponent
																		showDivider={menuIndex < optionGroup.menus.length - 1}
																		key={menu.menuId}
																		getMappingValue={getMappingValue}
																		form={form}
																		ignored={isNone}
																		section={PARENT_MAPPING_OPTIONS.OPTION_GROUP}
																		optionGroupId={optionGroup.optionGroupId}
																		menuItem={menu}
																	/>
																))
															}
														</div>
														{
															index < optionGroupList.length - 1 && <Divider className='m-0' />
														}
													</>
												))
											}
										</div>
									</CardWrapper>
								</Form.Item>
							)
							: null
					}
					{/* End of Option Group Section */}

					{/* Action Section */}
					{
						<div className='flex justify-end gap-2'>
							<Button onClick={() => {
								Modal.confirm({
									icon: <ExclamationCircleOutlined />,
									title: locale.CancelMappingConfirmationModal.title,
									content: locale.CancelMappingConfirmationModal.content,
									cancelText: locale.CancelMappingConfirmationModal.cancel,
									okText: locale.CancelMappingConfirmationModal.ok,
									onOk: () => goBack(),
									okButtonProps: {danger: true},
									centered: true,
								});
							}} >{locale.cancel}</Button>
							<Button
								loading={loading}
								type='primary'
								htmlType='submit'>
								{locale.save}
							</Button>
						</div>
					}

					{/* End of Action Section */}

				</div>
			</Form>
		</>
	);
};

ManageMapping.defaultProps = {
	goBack: () => null,
	conditionConfig: {},
	menuData: {},
	mappingDetails: {},
	previousPhase: () => null,
	isEdit: false,
	hash: null,
	previewLabelRef: {},
	autoFillDummyRef: {},
	menuMaster: {},
};

ManageMapping.propTypes = {
	goBack: PropTypes.func,
	conditionConfig: PropTypes.object,
	menuData: PropTypes.object,
	mappingDetails: PropTypes.object,
	previousPhase: PropTypes.func,
	isEdit: PropTypes.bool,
	hash: PropTypes.string,
	previewLabelRef: PropTypes.object,
	autoFillDummyRef: PropTypes.object,
	menuMaster: PropTypes.object,
};

export default ManageMapping;