import React, {useState} from 'react';

import LocalPageHeader from 'components/LocalPageHeader';
import localization from 'localization';
import {Card, Form} from 'antd';
import {useLocation} from 'react-router-dom';

const CampaignRequest = () => {
	const locale = localization.InternalOrdering.CampaignRequest;
	const location = useLocation();
	const [form] = Form.useForm();

	const campaignType = location?.state?.campaignType || null;

	const [loading, setLoading] = useState(false);

	const handleSubmit = async e => {
		try {
			console.log(e);
		} catch (error) {

		}
	};

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle={locale.title.replace('{{type}}', campaignType)}
			/>
			<div className='px-6'>
				<Form
					form={form}
					layout="vertical"
					name="adminUserForm"
					scrollToFirstError
					onFinish={e => !loading && handleSubmit(e)}
					onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
					requiredMark={false}
				>
					<div className='flex flex-col gap-6'>
						<Card
							title={locale.requestDetail}
						>
							<div></div>
						</Card>
						<Card title={locale.deliveryDetail} />
					</div>
				</Form>
			</div>
		</div>
	);
};

export default CampaignRequest;