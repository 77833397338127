import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';
import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';

import {Button, Upload} from 'antd';
import {DownloadOutlined, EditOutlined, ExportOutlined, ImportOutlined} from '@ant-design/icons';

import {OUTLET_TYPE, USER_ROLES} from 'utils/constants';
import localization from 'localization';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';
import ProtectedComponent from 'components/ProtectedComponent';
const locale = localization.Outlet.Location.Header;


const OutletLocationHeader = ({handleExport, refetchTableRef}) => {
	const navigate = useNavigate();

	const [uploadedFile, setUploadedFile] = useState(null);
	const [importCSV, setImportCSV] = useState({visible: false, type: 'add'});
	const [getCSV, setGetCSV] = useState({visible: false, type: 'export'});

	const openGetCSVOverlay = type => {
		setGetCSV({visible: true, type});
	};

	const closeGetCSVOverlay = () => {
		setGetCSV({...getCSV, visible: false});
	};

	const openImportCSVOverlay = type => {
		setImportCSV({visible: true, type});
	};

	const closeImportCSVOverlay = () => {
		setUploadedFile(null);
		setImportCSV({...importCSV, visible: false});
	};

	const handleFileUpload = async (e, type = 'add') => {
		if (!e.fileList.length) return;

		const uploadedFileTemp = e.fileList[0].originFileObj;

		setUploadedFile(uploadedFileTemp);
		openImportCSVOverlay(type);
	};

	return (
		<div>
			<ImportCSVOverlay
				masterlistType={OUTLET_TYPE.LOCATION}
				refetchTable={() => refetchTableRef?.current?.({reset: true, highlightNewData: true})}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importCSV.visible}
				importType={importCSV.type}
				close={closeImportCSVOverlay}
			/>
			<ExportCSVOverlay
				masterlistType={OUTLET_TYPE.LOCATION}
				handleExport={handleExport}
				visible={getCSV.visible}
				close={closeGetCSVOverlay}
				type={getCSV.type}
			/>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={(
					<>
						<ProtectedComponent when={
							u => u.roles.some(role => [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR].includes(role))}>
							<Button
								icon={<DownloadOutlined />}
								onClick={() => openGetCSVOverlay('download-template')}
							>{locale.downloadTemplate}</Button>
							<Button
								icon={<ExportOutlined />}
								onClick={() => openGetCSVOverlay('export')}
							>{locale.export}</Button>
							<Upload
								accept=".tsv"
								className='inline-block'
								showUploadList={false}
								beforeUpload={() => false}
								maxCount={1}
								onChange={e => handleFileUpload(e, 'add')}
							>
								<Button
									type="primary"
									icon={<ImportOutlined />}>{locale.import}</Button>
							</Upload>
							<Button
								onClick={() => {
									navigate('/outlet/location/new');
								}}
								type='primary'
								icon={<EditOutlined />}
							>{locale.create}</Button>
						</ProtectedComponent>
					</>
				)}
			/>
		</div>
	);
};

OutletLocationHeader.defaultProps = {
	handleExport: () => null,
	refetchTableRef: {},
};

OutletLocationHeader.propTypes = {
	handleExport: PropTypes.func,
	refetchTableRef: PropTypes.object,
};

export default OutletLocationHeader;