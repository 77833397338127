import {MASTERLIST_TYPE, MENU_STATION, ORDER_MERCHANT_STATUS_CONST, OUTLET_TYPE, PARENT_MAPPING_OPTIONS, POS_PAIR_METHOD, USER_ROLES} from './constants';
import {notification, Tooltip} from 'antd';
import moment from 'moment';
import localization from 'localization';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import cloneDeep from 'lodash/cloneDeep';
import {optionGroupConverter} from './mapping';
import {get, setWith} from 'lodash';

export const numberFormat = (value = 0) => {
	return value ? value.toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
};

export const moneyFormat = ({value = 0, includeCurrency = true, currencyPrefix = null}) => {
	const output = numberFormat(Number(value) === 'NaN' ? 0 : value);
	const prefix = currencyPrefix ? currencyPrefix : localization.Global.Utils.currency;
	return includeCurrency ? `${prefix}${output}` : output;
};

export const inputNumberCurrencyFormatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const doubleDigitTimeFormat = time => {
	if (time < 10) {
		return `0${time}`;
	} else {
		return time;
	}
};

export const dateFormat = (dateString, includeTime = true) => {
	const dateInstance = new Date(dateString);
	const options = {year: 'numeric', month: 'short', day: 'numeric'};
	const dateTemp = dateInstance.toLocaleString('id', options);

	if (includeTime) {
		return `${dateTemp} - ${doubleDigitTimeFormat(dateInstance.getHours())}:${doubleDigitTimeFormat(dateInstance.getMinutes())}`;
	} else {
		return dateTemp;
	}

};

export const momentDateFormat = ({dateString, format = 'DD-MM-YYYY'}) => {
	const momentDate = moment(dateString);
	if (!dateString || !momentDate.isValid()) {
		return '-';
	} else {
		return momentDate.format(format);
	}
};

export const localeCurrencyFormat = ({value, includeCurrency = true, withDecimal = false}) => {
	let options = {
		...(withDecimal ? {} : {minimumFractionDigits: 0, maximumFractionDigits: 0}),
	};
	const currencyLabel = includeCurrency ? 'Rp' : '';
	return `${currencyLabel}${value?.toLocaleString('id-ID', options)}`;
};

export const convertToBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
};

export const generateBreadcrumbPath = () => {
	const homePath = {
		path: location.origin,
		breadcrumbName: 'Home',
	};
	const locationPathsArr = location.pathname.split('/') || [];
	const locationPathsTemp = [];

	let nextUrl = '';
	let labelTemp = '';

	locationPathsArr.forEach(path => {
		if (!path) {
			locationPathsTemp.push(homePath);
		} else {
			nextUrl += `/${path}`;
			labelTemp += path.split('-').join(' ');
			if (path === 'masterlist') {
				labelTemp += '-';
				return;
			}
			const pathTemp = {
				path: location.origin + nextUrl,
				breadcrumbName: labelTemp,
			};
			locationPathsTemp.push(pathTemp);
		}
	});
	return locationPathsTemp;
};

export const determineCMSURL = masterlistType => {
	switch (masterlistType) {
	case MASTERLIST_TYPE.CATEGORY:
		return 'menu-category';
	case MASTERLIST_TYPE.MENU:
	case MASTERLIST_TYPE.CHILD_MENU:
		return 'master-menu';
	case MASTERLIST_TYPE.REMINDER:
		return 'menu-reminder';
	// case MASTERLIST_TYPE.OPTION_GROUP:
	// 	return 'menu-modifier';
	case MASTERLIST_TYPE.OPTION_GROUP:
		return 'option-group';
	case MASTERLIST_TYPE.MATERIAL:
		return 'material';
	case 'menu-template':
		return 'menu-template';
	case OUTLET_TYPE.LOCATION:
		return OUTLET_TYPE.LOCATION;
	case OUTLET_TYPE.BRAND:
		return OUTLET_TYPE.BRAND;
	case OUTLET_TYPE.MERCHANT:
		return OUTLET_TYPE.MERCHANT;
	case OUTLET_TYPE.BRANCH:
		return OUTLET_TYPE.BRANCH;
	case OUTLET_TYPE.COMPANY:
		return OUTLET_TYPE.COMPANY;
	case OUTLET_TYPE.MDR:
		return OUTLET_TYPE.MDR;
	case OUTLET_TYPE.POS_USER:
		return 'pos/user';
	default:
		return 'master-menu';
	}
};

export const generateMasterlistCSVTemplate = masterlistType => {
	switch (masterlistType) {
	case MASTERLIST_TYPE.MENU:
	case MASTERLIST_TYPE.CHILD_MENU:
		return 'name	code	kdsName	active	description	price	image	tags	station';
	case MASTERLIST_TYPE.CATEGORY:
		return 'name	posName	active	tags	sunday	monday	tuesday	wednesday	thursday	friday	saturday';
	case MASTERLIST_TYPE.OPTION_GROUP:
		return 'name	active	tags';
	case MASTERLIST_TYPE.MATERIAL:
		return 'material_label	material_code	parent	child';
	case MASTERLIST_TYPE.REMINDER:
		return 'name	active	tags	station';
	case 'menu-template':
		return 'categoryId	categoryLabel	menuId	menuCode	menuLabel	menuPrice	description	imageUrl	optionGroupId';
	default:
		return 'name	code	active	description	price	image	tags';
	}
};

export const generateOutletCSVTemplate = type => {
	switch (type) {
	case OUTLET_TYPE.LOCATION:
		return 'label	region	tags	shortAddress	branchID';
	case OUTLET_TYPE.BRAND:
		return 'label	shortName';
	case OUTLET_TYPE.MERCHANT:
		return 'label	brandId	locationId	platformId	listingUrl	menuTemplateId';
	case OUTLET_TYPE.MDR:
		return 'platformID,platformName,brandID,brandName,MDR,cofunding,effectiveDate,type';
	case OUTLET_TYPE.COMPANY:
		return 'refer to our internal merchant id which you can find on outlet activation page,refer to the merchant PT name,define whether we want to print "include pb1" or not in our receipt,define when the changes will take effect\nmerchantId,companyName,pb1,effectiveDate';
	case OUTLET_TYPE.POS_USER:
		return 'pin	username	locationId	role';
	default:
		return 'label	region	tags';
	}
};

export const getSignedURLUploadType = (type, importType = 'add') => {
	switch (type) {
	case MASTERLIST_TYPE.MENU:
		if (importType === 'add') {
			return 'master_menus';
		} else {
			return 'master_menus_update';
		}
	case MASTERLIST_TYPE.CHILD_MENU:
		if (importType === 'add') {
			return 'master_menus_child';
		} else {
			return 'master_menus_child_update';
		}
	case MASTERLIST_TYPE.CATEGORY:
		if (importType === 'add') {
			return 'menu_categories';
		} else {
			return 'menu_categories_update';
		}
	case MASTERLIST_TYPE.OPTION_GROUP:
		if (importType === 'add') {
			return 'menu_modifiers';
		} else {
			return 'menu_modifiers_update';
		}
	case MASTERLIST_TYPE.MATERIAL:
		if (importType === 'add') {
			return 'materials';
		} else if (importType === 'update') {
			return 'materials_update';
		} else {
			return 'materials_menu_update';
		}
	case MASTERLIST_TYPE.REMINDER:
		if (importType === 'add') {
			return 'menu_reminder';
		} else {
			return 'menu_reminder_update';
		}
	case 'menu-template': // TODO
		return 'menu_templates';
	case OUTLET_TYPE.BRAND:
		if (importType === 'add') {
			return 'brands';
		} else {
			return 'brands_update';
		}
	case OUTLET_TYPE.LOCATION:
		if (importType === 'add') {
			return 'locations';
		} else {
			return 'locations_update';
		}
	case OUTLET_TYPE.MERCHANT:
		if (importType === 'add') {
			return 'merchants';
		} else {
			return 'merchants_update';
		}
	case 'sync-request':
		return 'sync_menus';
	case 'settlement':
		return 'settlement_report';
	case 'pos':
		return 'bulk_pair_report';
	case 'pos-settings-payment':
		return 'pos_payment_method';
	case 'pos-settings-sales-channel':
		return 'pos_sales_channel';
	case OUTLET_TYPE.MDR:
		return 'mdrs';
	case OUTLET_TYPE.COMPANY:
		return 'companies';
	case OUTLET_TYPE.POS_USER:
		return 'pos_user';
	default:
		if (importType === 'add') {
			return 'master_menus';
		} else {
			return 'master_menus_update';
		}
	}
};

export const getLabel = (itemType, separator = ' ', capitalize = false) => {
	if (capitalize) {
		let output = itemType.split('-');
		output = output.map(el => el.charAt(0).toUpperCase() + el.slice(1));
		return output.join(separator);
	} else {
		return itemType.split('-').join(separator);
	}
};

export const promoRequestPayloadConverter = promoData => {
	return {
		menuTemplate: promoData?.MenuTemplate,
		label: promoData?.label,
		salesChannelId: promoData?.platformId,
		promoPeriod: {
			startDate: moment(promoData?.startDate),
			endDate: moment(promoData?.endDate),
		},
		promoActiveDays: promoData?.campaignDetails?.days,
		promoActiveHours: {
			start: moment(promoData?.campaignDetails?.activeHours?.from, 'HH:mm'),
			end: moment(promoData?.campaignDetails?.activeHours?.to, 'HH:mm'),
		},
		merchants: promoData?.merchantId,
		tags: promoData?.tags,
		promoMaxUsage: promoData?.campaignDetails?.maxQuotas?.total,
		promoMaxUsagePerUser: promoData?.campaignDetails?.maxQuotas?.user,
		rawMenus: promoData?.campaignDetails?.campaigns,
		templateType: promoData?.templateType,
		syncRequestId: promoData?.syncRequestId,
		campaignDetails: promoData?.campaignDetails,
	};
};

export const transferOutletFormatter = (outletData, showPlatform = false) => {
	/**
	 * Format:
	 * [Brand Label] - [Location Tags] - [Location Label] - [Platform Label?]
	 *
	 * ? = Optional
	 */
	return `
	${outletData?.Brand?.label} - ${outletData?.Location?.tags?.length ? outletData?.Location?.tags?.join(',') : '[]'} - ${outletData?.Location?.label}${showPlatform ? ` - ${outletData?.Platform?.label}` : ''}
	`;
};

export const isURL = urlString => {
	const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return pattern.test(urlString);
};

export const exportToTsvFn = async ({fileName, actionType = 'template', exportCall, tsvTemplate, fileType = 'tsv'}) => {

	/**
	 * fileName = file name output,
	 * actionType = one of template or export
	 * exportCall = api called if actionType is export
	 * tsvTemplate = generated template if actionType is template
	 */

	let fileInstance = null;
	if (actionType === 'export') {
		const blobFile = await exportCall();
		fileInstance = blobFile;
	} else {
		const templateFile = new File([tsvTemplate], fileName, {type: `text/${fileType}`});
		fileInstance = templateFile;
	}

	fileInstance = await convertToBase64(fileInstance);

	const downloadLink = document.createElement('a');
	downloadLink.href = fileInstance;
	downloadLink.download = fileName;

	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export const requestCardPayloadConverter = cardData => {
	const output = {
		...cardData,
		actionTime: cardData?.scheduledAt || cardData?.startDate,
		label: cardData?.label || cardData?.MenuTemplate?.label,
	};
	return output;
};

export const handleErrorFetch = (error, errorLocale) => {
	const locale = isEmpty(errorLocale) ? localization.Global.ErrorHandler.ErrorNotification : errorLocale;
	notification.open({
		message: locale?.message,
		description:
			error?.response?.data?.message ||
			error?.message ||
			locale?.description ||
			localization.Global.ErrorHandler.ErrorNotification.description,
		type: 'warning',
	});
};

export const authChecker = (args = {
	auth: {},
	requiredRolesArr: [],
	children: true,
}) => {
	const {auth, requiredRolesArr, children = true} = args;
	return auth?.roles?.some(role => [...requiredRolesArr, USER_ROLES.SUPERADMIN].includes(role)) ? children : null;
};

export const addTagToString = (locale, stringToReplace, renderElement) => {
	const result = flatMap(locale.split(stringToReplace), function (part, index) {
		return [part, <span key={index}>{renderElement}</span>];
	});
	result.pop();
	return result;
};

export const getPlatformImage = platform => {
	try {
		return require(`assets/images/platforms/${platform}.png`);
	} catch {
		return require('assets/images/platforms/Hangry App.png');
	}
};

export const cartesian = (...args) => {
	const r = [], max = args.length-1;
	function helper(arr, i) {
		for (let j=0, l=args[i].length; j<l; j++) {
			const a = arr.slice(0); // clone arr
			a.push(args[i][j]);
			if (i==max)
				r.push(a);
			else
				helper(a, i+1);
		}
	}
	helper([], 0);
	return r;
};

export const ellipsisItem = content => (
	<div className='grid place-content-stretch'>
		<div className='whitespace-nowrap overflow-ellipsis overflow-hidden'>
			<Tooltip title={content}>
				{content}
			</Tooltip>
		</div>
	</div>
);

export const salesPayloadHandler = metricObj => {

	// remove key with falsy value from object
	const tempMetricObj = cloneDeep(metricObj);
	for (let key in tempMetricObj) {
		if (tempMetricObj.hasOwnProperty(key) && !tempMetricObj[key]) {
			delete tempMetricObj[key];
		}
	}

	if (tempMetricObj?.sales_date?.length) {
		tempMetricObj.sales_date_from = tempMetricObj?.sales_date[0].format('YYYY-MM-DD');
		tempMetricObj.sales_date_to = tempMetricObj?.sales_date[1].format('YYYY-MM-DD');
		delete tempMetricObj.sales_date;
	}

	if (tempMetricObj?.createdAt?.length) {
		if (Array.isArray(tempMetricObj?.createdAt)) {
			tempMetricObj.createdAtFrom = tempMetricObj?.createdAt[0];
			tempMetricObj.createdAtTo = tempMetricObj?.createdAt[1];
		} else {
			tempMetricObj.createdAtFrom = tempMetricObj?.createdAt;
			tempMetricObj.createdAtTo = tempMetricObj?.createdAt;
		}
		delete tempMetricObj.createdAt;
	}

	for (let key in tempMetricObj) {
		// Check if its array (multiple select)
		if (Array.isArray(tempMetricObj[key])) {
			tempMetricObj[key] = tempMetricObj?.[key].join(',');
		}
	}

	if (tempMetricObj.created_at) {
		tempMetricObj.created_at = tempMetricObj.created_at.format('YYYY-MM-DD');
	}

	for (let key in tempMetricObj) {
		if (!tempMetricObj[key]) {
			delete tempMetricObj[key];
		}
	}

	return tempMetricObj;
};

export const menuTemplateBodyFormatter = menuTemplateObj => {
	return menuTemplateObj.map(category => {
		const categoryOutput = {
			...category,
			menus: category.menus.map(menu => {
				const menuOutput = {...menu};

				if (isEmpty(menuOutput.locationFilter)) {
					menuOutput.locationFilter = {
						type: 'default',
						locationIds: [-1],
					};
				}
				return menuOutput;
			}),
		};

		if (isEmpty(categoryOutput.locationFilter)) {
			categoryOutput.locationFilter = {
				type: 'default',
				locationIds: [-1],
			};
		}

		if (categoryOutput?.categoryDays?.length) return categoryOutput;
		else {
			categoryOutput.categoryDays = [...Array(7).keys()];
			return categoryOutput;
		}
	});
};

export const merchantOrderStatusHandler = ({status, type, pickUpReadyAt}) => {
	let labelOutput = Object.values(ORDER_MERCHANT_STATUS_CONST)
		.find(MERCHANT_STATUS => (MERCHANT_STATUS.value === status))
		?.label;

	if (type === 'takeaway') {
		if (pickUpReadyAt) labelOutput = 'Ready To Pick Up';
		if (status === 'delivered') labelOutput = 'Picked Up';
	}
	return labelOutput || '-';
};

export const sortDirectionConverter = direction => {
	// Used to convert antd direction to API direction value
	switch (direction) {
	case 'ascend':
		return 'asc';
	case 'descend':
		return 'desc';
	default:
		return 'desc';
	}
};

export const removeEmptyFields = obj => {
	for (let prop in obj) {
		if (obj[prop] === null || obj[prop] === '' || obj[prop] === undefined) {
			delete obj[prop];
		}
	}
};

export const getStationLabel = station => {
	switch (station) {
	case MENU_STATION.KITCHEN:
		return 'Kitchen Station';
	case MENU_STATION.BAR:
		return 'Barista Station';
	case MENU_STATION.NONE:
	default:
		return 'None Station';
	}
};

export const removeEmptyKeys = obj => {
	if (typeof obj !== 'object' || obj === null) {
		return obj; // Return non-object values as-is
	}

	if (Array.isArray(obj)) {
		// If obj is an array, filter out empty values and recursively clean nested objects
		return obj.map(item => removeEmptyKeys(item)).filter(item => item !== null);
	}

	// For objects, recursively clean nested objects and remove empty keys
	const cleanedObject = {};
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const cleanedValue = removeEmptyKeys(obj[key]);
			if (cleanedValue !== null && cleanedValue !== undefined && cleanedValue !== '') {
				cleanedObject[key] = cleanedValue;
			}
		}
	}
	return Object.keys(cleanedObject).length > 0 ? cleanedObject : null; // Return null if object becomes empty
};

export const removeNullValues = obj => {
	for (const key in obj) {
	  if (obj[key] === null) {
			delete obj[key];
	  }
	}
	return obj;
};

export const pairingMethodMapper = pairMethod => {
	switch (pairMethod) {
	case POS_PAIR_METHOD.AUTO:
		return 'auto';
	case POS_PAIR_METHOD.MANUAL:
		return 'order';
	case POS_PAIR_METHOD.FORCE:
		return 'manual';
	default:
		return '-';
	}
};

export const getPosUserPreset = () => {
	const output = [
		{roleName: 'Cashier', roles: [USER_ROLES.POS.CASHIER]},
		{roleName: 'PIC', roles: [USER_ROLES.POS.PIC]},
		{roleName: 'SM', roles: [USER_ROLES.POS.SM]},
	];
	return output;
};

export const getMappingConfig = (mappingDetails = {}) => {
	let selectedOptionGroupId = null;
	let selectedParentMenu = PARENT_MAPPING_OPTIONS.NONE;
	let selectedOptionGroupsId = mappingDetails.complexMenu?.optGroups || [];

	for (let key in mappingDetails.optionGroups) {
		if (mappingDetails.optionGroups[key].isParent) {
			selectedOptionGroupId = key;
			selectedParentMenu = PARENT_MAPPING_OPTIONS.OPTION_GROUP;
		}
	}

	if (mappingDetails.complexMenu?.isParent) {
		selectedParentMenu = PARENT_MAPPING_OPTIONS.COMPLEX_RULE;
	}

	if (mappingDetails.menu.isParent) {
		selectedParentMenu = PARENT_MAPPING_OPTIONS.MENU;
	}

	return {
		selectedOptionGroupId,
		selectedParentMenu,
		selectedOptionGroupsId,
	};
};

const checkIsParent = ({section, optionGroupId, selectedParentMenu, selectedOptionGroupId}) => {
	return selectedParentMenu === PARENT_MAPPING_OPTIONS.OPTION_GROUP
		? selectedOptionGroupId == optionGroupId ? true : false
		: selectedParentMenu === section ? true : false;
};

export const handleFillDummy = (isParent, isBundle) => {
	const output = {
		menuType: isParent,
		menuId: '49470',
		menuLabel: 'DUMMY MENU FOR FRESTO',
		displayName: 'DUMMY MENU FOR FRESTO',
		menuCode: 'DM001',
	};

	if (isBundle) output.menuPrice = 0;

	return output;
};

export const generateMappingPayload = (params = {}) => {
	const {
		mappingDetails = {},
		selectedParentMenu = null,
		selectedOptionGroupId = null,
		asComplexRule = [],
		asOptionGroup = [],
		fillDummy = false,
		isBundle = false,
	} = params;
	const mappingExist = !isEmpty(mappingDetails);
	// Option Group to render
	let optionGroupList = asOptionGroup;
	let complexRuleList = [];

	const checkIsParentHandler = ({section}) => {
		return checkIsParent({section, selectedParentMenu, selectedOptionGroupId});
	};

	const menuPayload = {
		isParent: checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.MENU}),
		items: [],
	};

	const formPayload = {
		menu: menuPayload,
		optionGroups: {},
	};

	if (selectedParentMenu == PARENT_MAPPING_OPTIONS.NONE) {
	} else {
		// Menu payload
		if (mappingExist && mappingDetails[PARENT_MAPPING_OPTIONS.MENU]?.items?.length !== 0) {
			menuPayload.items = mappingDetails[PARENT_MAPPING_OPTIONS.MENU].items.map((item, index)=> {
				if (index === 0) {
					if (!item.menuId && fillDummy) return handleFillDummy(
						checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.MENU}) ? 'parent' : 'child',
						isBundle,
					);
					return {
						...item,
						menuType: checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.MENU}) ? 'parent' : 'child',
					};
				} else return item;
			});
		} else {
			if (selectedParentMenu !== PARENT_MAPPING_OPTIONS.MENU) {
			} else {
				const menuItemPayload = {
					menuType: checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.MENU}) ? 'parent' : 'child',
					menuId: null,
					menuLabel: null,
					displayName: null,
					menuCode: null,
				};

				if (isBundle) menuItemPayload.menuPrice = 0;
				menuPayload.items.push(menuItemPayload);
			}
		}

		// Option group payload
		formPayload.optionGroups = optionGroupConverter({
			optionGroups: asOptionGroup || [],
			selectedOptionGroupId,
			mappedData: mappingDetails[PARENT_MAPPING_OPTIONS.OPTION_GROUP],
			fillDummy,
		});

		if (asComplexRule.length) {
			const separatedMenus = asComplexRule.map(rule => {
				return rule.menus;
			});
			const tempComplexRuleList = cartesian(...separatedMenus);
			complexRuleList = tempComplexRuleList;

			const complexRuleTemp = {
				isParent: checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.COMPLEX_RULE}),
				optGroups: asComplexRule.map(optGroup => optGroup.optionGroupId),
				items: {},
			};

			tempComplexRuleList.forEach(complexRule => {
				const complexDepth = complexRule.map(rule => rule.menuId);
				setWith(complexRuleTemp, ['items', ...complexDepth],
					mappingExist && !isEmpty(mappingDetails[PARENT_MAPPING_OPTIONS.COMPLEX_RULE])
						? get(mappingDetails, [PARENT_MAPPING_OPTIONS.COMPLEX_RULE, 'items', ...complexDepth]).map((item, index)=> {
							if (index === 0) {
								if (!item.menuId && fillDummy) return handleFillDummy(checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.COMPLEX_RULE}) ? 'parent' : 'child');
								return {
									...item,
									menuType: checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.COMPLEX_RULE}) ? 'parent' : 'child',
								};
							} else return item;
						})
						: [{
							menuType: checkIsParentHandler({section: PARENT_MAPPING_OPTIONS.COMPLEX_RULE}) ? 'parent' : 'child',
							menuId: null,
							menuLabel: null,
							displayName: null,
							menuCode: null,
						}], Object);
			});

			formPayload.complexMenu	= complexRuleTemp;
		}
	}

	return {formPayload, optionGroupList, complexRuleList};
};

export const getMappingRawConfig = (configs = {}, menuDetails = {}) => {
	const {selectedOptionGroupId, selectedOptionGroupsId, selectedParentMenu} = configs;
	const payload = {selectedParentMenu};

	if (selectedOptionGroupId) payload.selectedOptionGroupId = selectedOptionGroupId;

	const asComplexRule = [];
	const asOptionGroup = [];

	menuDetails?.optionGroups?.forEach(optionGroup => {
		if (selectedOptionGroupsId?.includes(optionGroup.optionGroupId)) asComplexRule.push(optionGroup);
		else asOptionGroup.push(optionGroup);
	});

	payload.asComplexRule = asComplexRule;
	payload.asOptionGroup = asOptionGroup;

	return payload;
};

export const calculateCartMenuprice = (cartItemData, quantity) => {
	const optionsTotal = cartItemData?.menuItem?.optionGroups.reduce((totalPrice, group) => {
		// Check if the group has an options array
		if (!Array.isArray(group.options)) {
			return totalPrice; // Skip this group if it doesn't have options
		}

		// Sum up the prices of options in this group
		const groupTotal = group.options.reduce((groupPrice, option) => {
			// Ensure menuPrice and quantity are numbers
			const price = Number(option.menuPrice) || 0;
			const quantity = Number(option.quantity) || 1;

			return groupPrice + (price * quantity);
		}, 0);

		return totalPrice + groupTotal;
	}, 0);

	return (cartItemData?.menuItem?.menuPrice + optionsTotal) * quantity;
};

export const cartCompressHandler = getter => {
	const mergedItems = [];

	getter.forEach(item => {
		if (item.menuItem.notes == null) item.menuItem.notes = '';
		const existingItem = mergedItems.find(
			mergedItem =>
				mergedItem.mappingHash === item.mappingHash &&
			(mergedItem.menuItem.notes === item.menuItem.notes ||
        (!mergedItem.menuItem.notes && !item.menuItem.notes)) &&
        JSON.stringify(mergedItem.menuItem.optionGroups) === JSON.stringify(item.menuItem.optionGroups),
		);

		if (existingItem) {
			existingItem.menuItem.quantity += item.menuItem.quantity;
		} else {
			mergedItems.push({...item});
		}
	});

	return mergedItems;
};