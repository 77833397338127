import React, {useRef, useState} from 'react';
import {FormOutlined, ImportOutlined} from '@ant-design/icons';
import {Button, Dropdown, notification} from 'antd';
import LocalPageHeader from 'components/LocalPageHeader';
import localization from 'localization';
import {useNavigate} from 'react-router-dom';
import NewCampaignModal from './NewCampaignModal';
import {handleErrorFetch} from 'utils/utils';
import CampaignListTable from './CampaignListTable';
import {INTERNAL_ORDERING_TYPE} from 'utils/constants';
import {createNewCampaign, editCampaign} from 'utils/request/internalOrdering';

const INITIAL_CAMPAIGN_MODAL = {
	visible: false,
	isEdit: false,
	campaignData: '',
	campaignType: null,
};

const OrderCreator = () => {
	const locale = localization.InternalOrdering.OrderCreator;
	const navigate = useNavigate();

	const [newCampaignModalConfig, setNewCampaignModalConfig] = useState(INITIAL_CAMPAIGN_MODAL);
	const [loadingCreate, setLoadingCreate] = useState(false);

	const refetchTableRef = useRef(null);

	const handleOkCampaignModal = async (campaignName, campaignData) => {
		const isEdit = newCampaignModalConfig.isEdit;
		const notificationLocaleType = isEdit ? 'Edit' : 'Create';
		try {
			setLoadingCreate(true);
			const callFn = isEdit ? editCampaign : createNewCampaign;
			const payload = isEdit ? {
				id: campaignData.id,
				name: campaignName,
			} : {
				name: campaignName,
				type: newCampaignModalConfig.campaignType,
			};

			const response = await callFn(payload);
			if (response.success) {
				notification.success({
					message: locale[`${notificationLocaleType}SuccessNotification`].message,
					description: locale[`${notificationLocaleType}SuccessNotification`].description,
				});
				if (!isEdit) navigate(`/internal-ordering/order-creator/campaign-detail/${response.data.id}`);
				else refetchTableRef?.current?.();
			}
		} catch (error) {
			handleErrorFetch({
				message: locale[`${notificationLocaleType}FailedNotification`].message,
				description: locale[`${notificationLocaleType}FailedNotification`].description,
			});
		} finally {
			setLoadingCreate(false);
			setNewCampaignModalConfig(INITIAL_CAMPAIGN_MODAL);
		}
	};

	const dropdownItems = INTERNAL_ORDERING_TYPE.map(type => {
		return {
			key: type.key,
			label: type.label,
			onClick: () => setNewCampaignModalConfig({
				visible: true,
				isEdit: false,
				campaignName: '',
				campaignType: type.key,
			}),
		};
	});

	const editCampaignConfirmation = campaignData => {
		setNewCampaignModalConfig({
			...INITIAL_CAMPAIGN_MODAL,
			visible: true,
			isEdit: true,
			campaignData,
		});
	};

	return (
		<>
			<NewCampaignModal
				open={newCampaignModalConfig.visible}
				isEdit={newCampaignModalConfig.isEdit}
				campaignData={newCampaignModalConfig.campaignData}
				onSave={handleOkCampaignModal}
				loading={loadingCreate}
				onClose={() => setNewCampaignModalConfig(INITIAL_CAMPAIGN_MODAL)}
				campaignType={newCampaignModalConfig.campaignType}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={locale.title}
					extra={[
						<Button
							onClick={() => null}
							icon={<ImportOutlined />}
							key='new'>{locale.import}
						</Button>,
						<Dropdown
							trigger={['click']}
							menu={{items: dropdownItems}}
							key='create'>
							<Button
								icon={<FormOutlined />}
								type='primary'
							>{locale.createNew}
							</Button>
						</Dropdown>,
					]}
				/>
				<CampaignListTable
					refetchTableRef={refetchTableRef}
					editCampaignConfirmation={editCampaignConfirmation} />
			</div>
		</>
	);
};

export default OrderCreator;