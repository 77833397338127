import React, {useState, useRef} from 'react';

import OutletLocationHeader from './OutletLocationHeader';
import OutletTable from '../components/Table';

import {exportOutletData, getOutletData} from 'utils/request/outlet';
import {authChecker, exportToTsvFn, generateOutletCSVTemplate} from 'utils/utils';
import {OUTLET_TYPE, STORAGE_KEY, USER_ROLES} from 'utils/constants';

import {Select, Tag} from 'antd';
import {useSelector} from 'react-redux';

const OutletLocation = () => {
	const containerRef = useRef(null);
	const refetchTableRef = useRef(null);
	const auth = useSelector(state => state.user);

	const [exportQuery, setExportQuery] = useState(null);

	const scrollToTop = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
		}
	};

	const goToEditPage = record => {
		sessionStorage.setItem(STORAGE_KEY.OUTLET_ITEM_DETAIL, JSON.stringify(record));
		window.open('/outlet/location/edit', '_blank');
	};

	const tableColumn = [
		{
			title: 'Location ID',
			dataIndex: 'id',
			key: 'id',
			fixed: 'left',
		},
		{
			title: 'Location Name',
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'Short Address',
			dataIndex: 'shortAddress',
			key: 'shortAddress',
		},
		{
			title: 'Tags',
			dataIndex: 'tags',
			key: 'tags',
			renderFormItem: () => (
				<Select
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			),
			render: tags => (Array.isArray(tags) && tags.length) ? (
				<div className='flex flex-wrap gap-y-2'>
					{tags.map((tag, index) => (
						<Tag
							color='blue'
							className='uppercase'
							key={index}>{tag}
						</Tag>
					))}
				</div>
			) : '-',
		},
		{
			title: 'POS',
			dataIndex: ['Branch', 'Po', 'label'],
			key: 'posLabel',
			search: false,
		},
		{
			title: 'Branch ID',
			dataIndex: ['Branch', 'details', 'branchID'],
			key: 'branchID',
		},
		{
			title: 'Min Queue',
			dataIndex: 'minQueue',
			key: 'minQueue',
		},
		{
			title: 'Max Queue',
			dataIndex: 'maxQueue',
			key: 'maxQueue',
		},
		{
			title: 'Timezone',
			dataIndex: ['timezone', 'label'],
			key: 'timezone',
			search: false,
		},
		{
			title: 'Active',
			dataIndex: 'isActive',
			key: 'isActive',
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{record.isActive ? 'Yes' : 'No'}</div>
			),
		},
		...(authChecker({
			auth,
			requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
		}) ? [{
				title: 'Action',
				align: 'center',
				key: 'action',
				fixed: 'right',
				search: false,
				render: (_, record) => (
					<span
						onClick={() => goToEditPage(record)}
						className='cursor-pointer text-blue-400'
					>
						Edit
					</span>
				),
			}] : []),
	];

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportOutletData(exportQuery, OUTLET_TYPE.LOCATION),
			tsvTemplate: generateOutletCSVTemplate(OUTLET_TYPE.LOCATION),
		});
	};

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto">
			<OutletLocationHeader
				refetchTableRef={refetchTableRef}
				handleExport={handleExport}
			/>
			<OutletTable
				tableColumn={tableColumn}
				fetchFn={getOutletData}
				tableType={OUTLET_TYPE.LOCATION}
				refetchTableRef={refetchTableRef}
				scrollToTop={scrollToTop}
				setExportQuery={setExportQuery}
			/>
		</div>
	);
};

export default OutletLocation;