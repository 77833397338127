import React, {useImperativeHandle, useState, forwardRef, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Alert, notification, Select} from 'antd';
// import setWith from 'lodash/setWith';

import ModalWithInput from 'components/ModalWithInput';

import CheckMappingLoadingBar from './CheckMappingLoadingBar';
import ChooseCategory from './ChooseCategory';
import ManageCategory from './ManageCategory';
import Context from './Context';

import {getMenuTags, submitMenuTemplate} from 'utils/request/salesChannel';
import {handleErrorFetch} from 'utils/utils';
import {checkMappings} from 'utils/request/mapping';
import {AVAILABLE_POS, OUTLET_TYPE, REFETCH_MAPPING_REQUEST} from 'utils/constants';

import {getOutletData} from 'utils/request/outlet';
import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm;

const MenuTemplateFormBody = forwardRef(({
	menuTemplateLabel, menuTemplateObj, draftId, viewOnly, closeConfirmation, providedLocation, previewAsDep,
	cart, setCart, isMenuCreator,
}, ref) => {
	const navigate = useNavigate();
	const [locationList, setLocationList] = useState([]);
	const [previewAs, setPreviewAs] = useState(null);
	const [menuTagList, setMenuTagList] = useState([]);

	const [menuTemplateDraft, setMenuTemplateDraft] = useState({
		label: menuTemplateLabel,
		obj: menuTemplateObj || [],
	});

	const [step, setStep] = useState(
		menuTemplateObj.length
			? 2
			: 1,
	);
	const [submitModalVisible, setSubmitModalVisible] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const [loadingCheckMappings, setLoadingCheckMappings] = useState(false);

	// Every child menu in every menu structure must be unique despite having diffrent option group
	const [duplicateChildMenuFounds, setDuplicateChildMenuFounds] = useState([]);
	const [duplicateParentMenuFounds, setDuplicateParentMenuFounds] = useState([]);
	const [unmapMenu, setUnmapmMenu] = useState([]);

	const menuTemplateDraftRef = useRef(null);

	const updateMenuTemplate = menuState => {
		setMenuTemplateDraft({...menuTemplateDraft, obj: menuState});
	};

	const handleCheckMappings = async (isSubmit = false) => {
		try {
			const tempUnmapMenu = [];

			setLoadingCheckMappings(true);

			for (const posData of Object.values(AVAILABLE_POS)) {
				const response = await checkMappings({
					templateId: draftId,
					obj: menuTemplateDraftRef.current?.obj,
					posId: posData.id,
				});

				response.data.forEach(category => {
					category.menus.forEach(menu => {
						if (menu.hasMapping === false) tempUnmapMenu.push({...menu, posId: posData.id});
					});
				});
			}

			setUnmapmMenu(tempUnmapMenu);

			const filteredFromEsb = tempUnmapMenu
				.filter(item => item.posId !== AVAILABLE_POS.ODOO.id);

			if (tempUnmapMenu.length) {
				notification.open({
					message: locale.UnmapMenuNotification.message,
					description: locale.UnmapMenuNotification.description,
					type: 'error',
				});
			} else {
				if (!isSubmit) {
					notification.open({
						message: locale.MappedMenuNotification.message,
						description: locale.MappedMenuNotification.description,
						type: 'success',
					});
				}
			}

			return filteredFromEsb;
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingCheckMappings(false);
		}
	};

	const handleSubmitToOrderhub = async inputtedMenuTemplateLabel => {
		try {
			setLoadingSubmit(true);
			const payload = {
				...menuTemplateDraft,
				label: inputtedMenuTemplateLabel,
				templateId: draftId,
			};
			const response = await submitMenuTemplate(payload);
			if (response.success) {
				navigate('/sales-channel/menu-template', {replace: true});
				notification.open({
					message: locale.SubmitSuccessNotification.message,
					description: locale.SubmitSuccessNotification.description,
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingSubmit(false);
		}
	};

	const beforeSubmit = async () => {
		try {
			const unmapMenu = await handleCheckMappings(true);
			const mandatoryMapMenu = unmapMenu.filter(menu => menu.posId == 1);
			if (mandatoryMapMenu.length === 0) setSubmitModalVisible(true);
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const goToChooseCategory = () => setStep(1);
	const goToManageCategory = () => setStep(2);

	const renderForm = () => {
		switch (step) {
		case 1:
			return (
				<ChooseCategory
					menuTemplateDraftObj={menuTemplateDraft.obj}
					updateMenuTemplate={updateMenuTemplate}
					nextStep={goToManageCategory}
					closeConfirmation={closeConfirmation}
				/>
			);
		case 2:
			return (
				<ManageCategory
					handleSubmitToOrderhub={() => beforeSubmit()}
					handleCheckMappings={() => handleCheckMappings(false)}
					unmapMenu={unmapMenu}
					menuDraft={menuTemplateDraft.obj}
					setMenuDraft={updateMenuTemplate}
					viewOnly={viewOnly}
					goToChooseCategory={goToChooseCategory}
					closeConfirmation={closeConfirmation}
					duplicateChildMenuFounds={duplicateChildMenuFounds}
					duplicateParentMenuFounds={duplicateParentMenuFounds}
					cart={cart}
					setCart={setCart}

				/>
			);
		default:
			return (
				<ChooseCategory
					menuDraft={menuTemplateDraft.obj}
					setMenuDraft={updateMenuTemplate}
					nextStep={goToManageCategory}
					closeConfirmation={closeConfirmation}
				/>
			);
		}
	};

	useImperativeHandle(ref, () => ({
		getMenuTemplateState() {
			return menuTemplateDraft;
		},
	}));

	const listenMappingSubmit = event => {
		if (event.data === REFETCH_MAPPING_REQUEST) handleCheckMappings();
	};

	const fetchLocation = async () => {
		try {
			const response = await getOutletData({
				search: {limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const getLocation = () => {
		// Check if theres a provided location from parent (usually from sync review)
		if (providedLocation.length) {
			setLocationList(providedLocation);
		} else {
			fetchLocation();
		}
	};

	const fetchMenuTags = async () => {
		try {
			const response = await getMenuTags();
			if (response.success) {
				setMenuTagList(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const handlePreviewAs = locationId => {
		setPreviewAs(locationId);
		previewAsDep(locationId);
	};

	useEffect(() => {
		getLocation();
		fetchMenuTags();
		window.addEventListener('message', listenMappingSubmit, false);

		return () => {
			window.removeEventListener('message', listenMappingSubmit);
		};
	}, []);

	useEffect(() => {
		// Update menuTemplateDraftRef value for eventListener
		menuTemplateDraftRef.current = menuTemplateDraft;

		// Detect duplicate child menu ids
		const tempDuplicateIds = [];

		menuTemplateDraft?.obj?.forEach?.(category => {
			category.menus.forEach(menu => {
				menu.optionGroups.forEach(optionGroupI => {
					menu.optionGroups.forEach(optionGroupJ => {
						if (optionGroupI.optionGroupId !== optionGroupJ.optionGroupId) {
							if (optionGroupI.menus.some(childMenuI => optionGroupJ.menus.some(childMenuJ => Number(childMenuJ.menuId) === Number(childMenuI.menuId)))) {
								tempDuplicateIds.push({categoryId: category.categoryId, menuId: menu.menuId});
							}
						}
					});
				});
			});
		});

		setDuplicateChildMenuFounds(tempDuplicateIds);

		// Detect duplicate parent menu ids
		const parentIds = [];
		menuTemplateDraft?.obj?.forEach?.(category => {
			category.menus.forEach(menu => parentIds.push(menu.menuId));
		});

		const duplicateParentIds = parentIds.filter((e, i, a) => a.indexOf(e) !== i);
		setDuplicateParentMenuFounds(duplicateParentIds);
	}, [menuTemplateDraft]);

	const SubmitModal = (
		<ModalWithInput
			visible={submitModalVisible}
			close={() => setSubmitModalVisible(false)}
			inputDefaultValue={menuTemplateLabel}
			onOk={handleSubmitToOrderhub}
			loading={loadingSubmit}
			localization={{
				title: locale.SubmitModal.title,
				description: locale.SubmitModal.description,
				inputPlaceholder: locale.SubmitModal.inputPlaceholder,
				inputAddOn: locale.SubmitModal.inputAddOn,
				cancelText: locale.SubmitModal.cancelText,
				okText: locale.SubmitModal.okText,
			}}
		/>
	);

	return (
		<div className='px-6 h-full flex-1'>
			<CheckMappingLoadingBar visible={loadingCheckMappings} />
			{SubmitModal}
			{
				(step === 2 && !isMenuCreator) && (
					<div className='flex flex-col gap-4 mb-4'>
						<Select
							allowClear
							showSearch
							value={previewAs}
							onChange={e => handlePreviewAs(e)}
							placeholder='Select outlet to view'
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLocaleLowerCase())}
							className='w-96'>
							{locationList?.map?.(location => (
								<Select.Option
									label={location.label}
									value={location.id}
									key={location.id}>{location.label}</Select.Option>
							))}
						</Select>
						{
							previewAs && (
								<Alert
									type="info"
									showIcon
									message={
										locale.OutletPreviewAlert
											.replace(
												'{{outletLabel}}',
												locationList
													.find(location => location.id === previewAs)
													.label,
											)
									}
								/>
							)
						}
					</div>
				)
			}
			<Context.Provider
				value={{
					locationList,
					previewAs,
					viewOnly,
					menuTagList,
					isMenuCreator,
				}}
			>
				{renderForm()}
			</Context.Provider>
		</div>
	);
});

MenuTemplateFormBody.defaultProps = {
	menuTemplateLabel: null,
	menuTemplateObj: [],
	draftId: null,
	viewOnly: false,
	closeConfirmation: () => null,
	providedLocation: [],
	previewAsDep: () => null,
	cart: [],
	setCart: () => null,
	isMenuCreator: false,
};

MenuTemplateFormBody.propTypes = {
	menuTemplateLabel: PropTypes.string,
	menuTemplateObj: PropTypes.array,
	draftId: PropTypes.number,
	viewOnly: PropTypes.bool,
	closeConfirmation: PropTypes.func,
	providedLocation: PropTypes.array,
	previewAsDep: PropTypes.func,
	cart: PropTypes.array,
	setCart: PropTypes.func,
	isMenuCreator: PropTypes.bool,
};

export default MenuTemplateFormBody;