import React from 'react';
import {Button, Dropdown} from 'antd';
import LocalPageHeader from 'components/LocalPageHeader';
import localization from 'localization';
import {EditOutlined, FormOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';

const locale = localization.InternalOrdering.CampaignDetail;
const CAMPAIGN_TYPE = [
	{
		key: 'delivery',
		label: locale.delivery,
	},
	{
		key: 'takeaway',
		label: locale.takeaway,
	},
];

const CampaignDetail = () => {
	const navigate = useNavigate();
	const params = useParams();
	const campaignId = params?.campaignId || null;

	const dropdownItems = CAMPAIGN_TYPE.map(type => {
		return {
			...type,
			onClick: () => navigate(`/internal-ordering/order-creator/campaign-detail/${campaignId}/new`, {
				state: {
					campaignType: type.key,
				},
			}),
		};
	});

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={[
					<Button
						onClick={() => null}
						icon={<EditOutlined />}
						key='edit'>{locale.edit}
					</Button>,
					<Dropdown
						trigger={['click']}
						menu={{items: dropdownItems}}
						key='create'>
						<Button
							icon={<FormOutlined />}
							type='primary'
							key='new'>{locale.create}
						</Button>
					</Dropdown>,
				]}
			/>
		</div>
	);
};

export default CampaignDetail;