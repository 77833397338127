import PropTypes from 'prop-types';
import {Table, Typography} from 'antd';

// Global utils
import localization from 'localization';
import {moneyFormat} from 'utils/utils';

// Local styles
import './orderDetailModal.less';
import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {get, isEmpty} from 'lodash';

const OrderItems = ({data, posFormat, isPos}) => {
	const locale = localization.WatchTower.WatchTowerOrder.orderDetailModal.orderItems;
	const {orderItems, merchantItems, orderFee, cartDiscount, grandTotal, posPayload} = data;

	const [completedItemsToOrderDetailsMap, setCompletedItemsToOrderDetailsMap] = useState([]);
	const isReimburse = completedItemsToOrderDetailsMap.length;

	/**
	 * If orderItems is empty, read order item from merchantItems field (provided by platform)
	 * this can happen if the menus on the platform are not fully integrated
	 */
	const itemsEntry = isPos
		? data?.items
		: orderItems?.items?.length ? orderItems : merchantItems;

	const columns = [
		{
			title: locale.quantity,
			dataIndex: 'quantity',
			width: 80,
			render: (_, {quantity}) => <div className='align-top'>{quantity}</div>,
		},
		{
			title: locale.orderMade,
			width: 130,
			render: (_, {quantity}, index) => (
				<div className='align-top'>{
					isReimburse
						? completedItemsToOrderDetailsMap?.[index]?.length || 0
						: quantity
				}</div>
			),
		},
		{
			title: locale.menu,
			dataIndex: 'menuLabel',
			width: 164,
			render: (_, {menuLabel}) => <div className='align-top'>{menuLabel}</div>,
		},
		...(posFormat
			? [{
				title: locale.parentPrice,
				dataIndex: 'price',
				width: 140,
				render: (_, {price}) => <div className='align-top text-right'>{moneyFormat({value: price, includeCurrency: false})}</div>,
			}]
			: []),
		{
			title: locale.submenu,
			width: posFormat ? 'max-content' : 234,
			render: posFormat
				? ({packages}) => (
					<div className='flex flex-col'>
						{packages.map((submenu, index) => (
							<div
								key={index}
								className='flex justify-between gap-2'>
								<div>
									{submenu.qty} - {submenu.menuShortName}
								</div>
							</div>
						))}
					</div>
				)
				: ({optionGroups}) => {
				// eslint-disable-next-line react/prop-types
					const usedOptionGroups = optionGroups.filter(optionGroup => optionGroup?.options?.length);
					return usedOptionGroups?.length
						? usedOptionGroups.map(optionGroup => (
							<div key={optionGroup.optionGroupId}>
								<div className='font-medium'>
									{optionGroup.optionGroupLabel}
								</div>
								<ul className='list-disc pl-4'>
									{optionGroup.options.map((childMenu, index) => (
										<li key={index}>{childMenu.menuLabel}</li>
									))}
								</ul>
							</div>
						))
						: '-';
				},
		},
		{
			title: posFormat ? locale.childPrice : locale.price,
			width: 140,
			fixed: posFormat ? 'right' : null,
			// eslint-disable-next-line react/prop-types
			render: posFormat
				? ({packages}) => (
					<div className='flex flex-col'>
						{packages.map((submenu, index) => (
							<div
								className='text-right'
								key={index}>
								{moneyFormat({value: submenu.total, includeCurrency: false})}
							</div>
						))}
					</div>
				)
				: (_, record) => {
					// Child menu price is added to final price and cut price
					let childMenusPrice = 0;

					record.optionGroups.forEach(optionGroup => {
						optionGroup?.options?.forEach(childMenu => {
							childMenusPrice += childMenu.menuPrice;
						});
					});

					return (
						<div className='flex flex-col'>
							<Typography.Text>{moneyFormat({value: (record.listedPrice + childMenusPrice) * record.quantity})}</Typography.Text>
							{
								record.menuPrice > record.listedPrice && (
									<Typography.Text
										delete
										disabled>
										{moneyFormat({value: (record.menuPrice + childMenusPrice) * record.quantity})}
									</Typography.Text>
								)
							}
						</div>
					);
				},
		},
		...(posFormat
			? []
			: [{
				title: locale.notes,
				dataIndex: 'notes',
				width: 150,
			}]),
	];

	const PriceBreakdown = ({
		// eslint-disable-next-line react/prop-types
		label,value, isDeduction, isTotal,
	}) => (
		<div className={clsx('flex ml-auto', isTotal && 'font-bold')}>
			<div className='flex gap-3'>
				<div>{label}</div>
				<div>:</div>
			</div>
			<div
				className={clsx(
					'text-right',
					(isDeduction && value > 0) && 'text-antd-green-6',
				)}
				style={{width: 112}}
			>
				{`${(isDeduction && value > 0) ? '-' : ''}${moneyFormat({value})}`}
			</div>
		</div>

	);

	const posPayloadConverter  = posPayload => {
		if (posPayload?.salesMenu?.length) {
			const output = posPayload?.salesMenu.map(menu => {
				return {
					quantity: menu.qty,
					menuLabel: menu.menuShortName,
					packages: menu.packages,
					notes: menu.notes,
					price: menu.total,
				};
			});
			return output;
		} else return [];
	};

	useEffect(() => {
		if (isEmpty(data.reimbursement)) return setCompletedItemsToOrderDetailsMap([]);

		let indexCounter = 0;

		const orderContainerTemp = [];
		const madeItemsIndex = get(data, ['reimbursement', 'completedItems'], []);

		data.items.forEach(item => {
			const qtyPerItemArrTemp = [];

			new Array(item.quantity).fill(null).forEach(() => {
				if (madeItemsIndex.includes(indexCounter)) {
					qtyPerItemArrTemp.push(indexCounter);
				}
				indexCounter++;
			});

			orderContainerTemp.push(qtyPerItemArrTemp);
		});

		setCompletedItemsToOrderDetailsMap(orderContainerTemp);
	}, [data]);

	// Render
	return (
		<Table
			rowKey="id"
			className='OrderDetailTable'
			columns={columns}
			expandable={posFormat ? {
				rowExpandable: record => record.notes.length,
				expandedRowRender: record => locale.expandableNotes.replace('{{notes}}', record.notes),
			} : null}
			dataSource={posFormat
				? posPayloadConverter(posPayload)
				: isPos ? itemsEntry : itemsEntry?.items?.map?.(item => item.menuItem)}
			scroll={{x: 'max-content', y: 250}}
			pagination={false}
			footer={rows => (
				<div className="flex justify-between">
					<div>{locale.totalItems}: {rows.length}</div>
					<div
						style={{gap: 2}}
						className="flex flex-col">
						{PriceBreakdown({label: locale.subtotal,
							value: posFormat
								? (posPayload?.paymentTotal - posPayload?.orderFee)
								: isPos
									? data?.subtotal
									: itemsEntry?.subtotal || data?.orderItems?.subtotal || 0})}
						{(!isReimburse || posFormat) ? (
							<>
								{posFormat
									? null
									: PriceBreakdown({
										label: locale.voucherDiscount,
										value: isPos ? data?.orderDetails?.cartDiscount : cartDiscount,
										isDeduction: true,
									})}
								{PriceBreakdown({
									label: locale.packagingFee,
									value: posFormat
										? posPayload?.orderFee
										: isPos ? data?.orderDetails?.orderFee : orderFee,
								})}
							</>
						) : (
							<>
								{PriceBreakdown({
									label: locale.totalUnmadeItems,
									value: data?.subtotal - data?.reimbursementSubtotal,
									isDeduction: true,
								})}
							</>
						)}
						{PriceBreakdown({
							label: locale.totalAmount,
							value: posFormat
								? posPayload?.paymentTotal
								: isReimburse
									? data?.reimbursementSubtotal
									: grandTotal,
							isTotal: true,
						})}
					</div>
				</div>
			)
			}
		/>
	);
};

OrderItems.defaultProps = {
	data: {},
	posFormat: false,
	isPos: false,
};

OrderItems.propTypes = {
	data: PropTypes.object,
	posFormat: PropTypes.bool,
	isPos: PropTypes.bool,
};

export default OrderItems;