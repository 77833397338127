import {Navigate} from 'react-router-dom';

import Login from 'pages/Login';

import Home from 'pages/Home';
import Masterlist from 'pages/Masterlist';
import MasterlistForm from 'pages/Masterlist/MasterlistForm';

import MenuTemplate from 'pages/SalesChannel/MenuTemplate';
import MenuTemplateForm from 'pages/SalesChannel/MenuTemplateForm';
import MenuTemplateDetail from 'pages/SalesChannel/MenuTemplateDetail';
import SyncRequest from 'pages/SalesChannel/SyncRequest';
import ReviewTemplate from 'pages/SalesChannel/SyncRequest/ReviewTemplate';
import CreateExternalSyncRequest from 'pages/SalesChannel/CreateSyncRequest/ExternalSyncRequest';
import CreateInternalSyncRequest from 'pages/SalesChannel/CreateSyncRequest/InternalSyncRequest';
import SyncStatus from 'pages/SalesChannel/SyncStatus';
import MegaMenuTemplate from 'pages/SalesChannel/MegaMenuTemplate';
import MegaMenuTemplateDetail from 'pages/SalesChannel/MegaMenuTemplateDetail';

import PromoRequest from 'pages/Promo/PromoRequest';
import PromoStatus from 'pages/Promo/PromoStatus';
import PromoUsage from 'pages/Promo/PromoUsage';
import CreatePromoRequest from 'pages/Promo/CreatePromoRequest';
import PromoDetail from 'pages/Promo/PromoDetail';

import OutletLocation from 'pages/Outlet/Location';
import OutletLocationForm from 'pages/Outlet/Location/OutletLocationForm';
import OutletBranch from 'pages/Outlet/Branch';
import OutletBranchForm from 'pages/Outlet/Branch/OutletBranchForm';
import OutletBrand from 'pages/Outlet/Brand';
import OutletBrandForm from 'pages/Outlet/Brand/OutletBrandForm';
import OutletActivation from 'pages/Outlet/OutletActivation';
import UserActivation from 'pages/Outlet/UserActivation';
import TrainingSessionList from 'pages/Outlet/Training/TrainingSessionList';
import TrainingSession from 'pages/Outlet/Training/TrainingSession';
import MDR from 'pages/Outlet/MDR';
import Company from 'pages/Outlet/Company';

import UserList from 'pages/Admin/User/UserList';
import UserForm from 'pages/Admin/User/UserForm';
import VersionUploader from 'pages/Admin/VersionSettings/VersionUploader';
import VersionUploaderForm from 'pages/Admin/VersionSettings/VersionUploader/VersionUploaderForm';
import LocationGroup from 'pages/Admin/VersionSettings/LocationGroup';
import LocationGroupForm from 'pages/Admin/VersionSettings/LocationGroup/LocationGroupForm';
import ReleaseScheduler from 'pages/Admin/VersionSettings/ReleaseScheduler';
import ReleaseSchedulerForm from 'pages/Admin/VersionSettings/ReleaseScheduler/ReleaseSchedulerForm';
import VersionTracker from 'pages/Admin/VersionSettings/VersionTracker';

import WatchTowerMenu from 'pages/WatchTower/WatchTowerMenu';
import WatchTowerOrder from 'pages/WatchTower/WatchTowerOrder';
import WatchtowerOutlet from 'pages/WatchTower/WatchTowerOutlet';
import OrderDetailPage from 'pages/WatchTower/WatchTowerOrder/OrderDetailPage';
import WatchTowerPromo from 'pages/WatchTower/WatchTowerPromo';
import JobDetailPage from 'pages/WatchTower/WatchTowerPromo/JobDetailPage';

import FinalDashboard from 'pages/Tax/FinalDashboard';
import TaxFileUpload from 'pages/Tax/TaxFileUpload';
import FrestoxESB from 'pages/Tax/FrestoxESB';
import PlatformSettlement from 'pages/Tax/PlatformSettlement';
import OthersSettlement from 'pages/Tax/OthersSettlement';
import Checker from 'pages/Tax/Checker';

import Payment from 'pages/POSSetting/Payment';
import PaymentForm from 'pages/POSSetting/Payment/PaymentForm';
import SalesChannel from 'pages/POSSetting/SalesChannel';
import SalesChannelForm from 'pages/POSSetting/SalesChannel/SalesChannelForm';

import {PROMO_CATEGORY, USER_ROLES} from 'utils/constants';
import InternalPlatform from 'pages/POSSetting/InternalPlatform';
import InternalPlatformForm from 'pages/POSSetting/InternalPlatform/InternalPlatformForm';
import PrintPreview from 'pages/Misc/PrintPreview';

import MenuCreator from 'pages/InternalOrdering/MenuCreator';
import OrderCreator from 'pages/InternalOrdering/OrderCreator';
import MenuCreatorForm from 'pages/InternalOrdering/MenuCreator/MenuCreatorForm';
import CampaignDetail from 'pages/InternalOrdering/OrderCreator/CampaignDetail';
import CampaignRequest from 'pages/InternalOrdering/OrderCreator/CampaignRequest';

export default [
	{
		path: '/login',
		element: Login,
	},
	{
		path: '/preview-co-label',
		element: PrintPreview,
	},

	// Masterlist
	{
		exact: true,
		path: '/masterlist/:masterlistType',
		roles: Object.values(USER_ROLES.MASTERLIST),
		element: Masterlist,
	},
	{
		exact: true,
		path: '/masterlist/:masterlistType/new',
		element: MasterlistForm,
		roles: [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR],
	},
	{
		exact: true,
		path: '/masterlist/menu-structure/:posId/new',
		element: MasterlistForm,
		roles: [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR],
	},
	{
		exact: true,
		path: '/masterlist/menu-structure/:posId/:hash/edit',
		element: MasterlistForm,
		props: {
			isEdit: true,
		},
		roles: [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR],
	},
	{
		exact: true,
		path: '/masterlist/:masterlistType/edit',
		element: MasterlistForm,
		props: {
			isEdit: true,
		},
		roles: [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR],
	},

	// Sales Channel
	{
		exact: true,
		path: '/sales-channel/menu-template/new',
		roles: [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.EDITOR],
		element: MenuTemplateForm,
	},
	{
		exact: true,
		path: '/sales-channel/menu-template/detail',
		element: MenuTemplateDetail,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/',
		element: Navigate,
		props: {
			to: '/sales-channel/menu-template',
			replace: true,
		},
	},
	{
		exact: true,
		path: '/sales-channel/menu-template',
		element: MenuTemplate,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/mega-menu-template',
		element: MegaMenuTemplate,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/mega-menu-template/:templateId',
		element: MegaMenuTemplateDetail,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/sync-request/new',
		element: CreateExternalSyncRequest,
		roles: [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.EDITOR],
	},
	{
		exact: true,
		path: '/sales-channel/internal-sync-request/new',
		element: CreateInternalSyncRequest,
		roles: [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.EDITOR],
	},
	{
		exact: true,
		path: '/sales-channel/sync-request/review-template',
		element: ReviewTemplate,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/sync-request/free-item/:requestId',
		element: MegaMenuTemplateDetail,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/sync-request',
		element: SyncRequest,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},
	{
		exact: true,
		path: '/sales-channel/sync-status',
		element: SyncStatus,
		roles: Object.values(USER_ROLES.SYNC_MENU),
	},

	// Promo
	{
		exact: true,
		path: '/promo/promo-detail',
		element: PromoDetail,
		roles: Object.values(USER_ROLES.SYNC_PROMO),
	},
	{
		exact: true,
		path: '/promo/promo-request/free-item/new',
		element: CreatePromoRequest,
		roles: [USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.EDITOR],
		props: {
			promoCategory: PROMO_CATEGORY.INTERNAL,
		},
	},
	{
		exact: true,
		path: '/promo/promo-request/price-cut/new',
		element: CreatePromoRequest,
		roles: [USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.EDITOR],
		props: {
			promoCategory: PROMO_CATEGORY.EXTERNAL,
		},
	},
	{
		exact: true,
		path: '/promo/promo-request/free-item',
		element: PromoRequest,
		roles: Object.values(USER_ROLES.SYNC_PROMO),
		props: {
			promoCategory: PROMO_CATEGORY.INTERNAL,
		},
	},
	{
		exact: true,
		path: '/promo/promo-request/price-cut',
		element: PromoRequest,
		roles: Object.values(USER_ROLES.SYNC_PROMO),
		props: {
			promoCategory: PROMO_CATEGORY.EXTERNAL,
		},
	},
	{
		exact: true,
		path: '/promo/promo-status/free-item',
		element: PromoStatus,
		roles: Object.values(USER_ROLES.SYNC_PROMO),
		props: {
			promoCategory: PROMO_CATEGORY.INTERNAL,
		},
	},
	{
		exact: true,
		path: '/promo/promo-status/price-cut',
		element: PromoStatus,
		roles: Object.values(USER_ROLES.SYNC_PROMO),
		props: {
			promoCategory: PROMO_CATEGORY.EXTERNAL,
		},
	},
	{
		exact: true,
		path: '/promo/promo-usage/internal',
		element: PromoUsage,
		roles: Object.values(USER_ROLES.SYNC_PROMO),
	},


	// Outlet
	{
		exact: true,
		path: '/outlet/location',
		element: OutletLocation,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.OUTLET.VIEWER],
	},
	{
		exact: true,
		path: '/outlet/location/new',
		element: OutletLocationForm,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/location/edit',
		element: OutletLocationForm,
		props: {
			isEdit: true,
		},
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/branch',
		element: OutletBranch,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/branch/new',
		element: OutletBranchForm,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/branch/edit',
		element: OutletBranchForm,
		props: {
			isEdit: true,
		},
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/brand',
		element: OutletBrand,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.OUTLET.VIEWER],
	},
	{
		exact: true,
		path: '/outlet/brand/new',
		element: OutletBrandForm,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/brand/edit',
		element: OutletBrandForm,
		props: {
			isEdit: true,
		},
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/outlet-activation',
		element: OutletActivation,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.OUTLET.VIEWER],
		props: {
			platformType: 'external',
		},
	},
	{
		exact: true,
		path: '/outlet/user-activation',
		element: UserActivation,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
		props: {
			isFresto: false,
		},
	},
	{
		exact: true,
		path: '/outlet/user-activation-fresto',
		element: UserActivation,
		roles: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
		props: {
			isFresto: true,
		},
	},
	{
		exact: true,
		path: '/outlet/training-session',
		element: TrainingSessionList,
		roles: [USER_ROLES.TRAINER.ADMIN],
	},
	{
		exact: true,
		path: '/outlet/training-session/:trainingId',
		element: TrainingSession,
		roles: [USER_ROLES.TRAINER.ADMIN],
	},
	{
		exact: true,
		path: '/outlet/mdr-settings',
		element: MDR,
		roles: [USER_ROLES.FINANCE.EDITOR, USER_ROLES.MARKETING.EDITOR],
	},
	{
		exact: true,
		path: '/outlet/company-settings',
		element: Company,
		roles: [USER_ROLES.FINANCE.EDITOR, USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER],
	},

	// Watchtower
	{
		exact: true,
		path: '/watchtower/menu',
		element: WatchTowerMenu,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.MENU_ADMIN],
	},
	{
		exact: true,
		path: '/watchtower/menu/history',
		element: WatchTowerMenu,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.MENU_ADMIN],
		props: {
			isHistory: true,
		},
	},
	{
		exact: true,
		path: '/watchtower/order',
		element: WatchTowerOrder,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.ORDER_ADMIN],
	},
	{
		exact: true,
		path: '/watchtower/order/pos',
		element: WatchTowerOrder,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.ORDER_ADMIN],
		props: {
			isPos: true,
		},
	},
	{
		exact: true,
		path: '/watchtower/outlet',
		element: WatchtowerOutlet,
		roles: [USER_ROLES.WATCHTOWER.ADMIN],
	},
	{
		exact: true,
		path: '/watchtower/outlet/history',
		element: WatchtowerOutlet,
		roles: [USER_ROLES.WATCHTOWER.ADMIN],
		props: {
			isHistory: true,
		},
	},
	{
		exact: true,
		path: '/watchtower/order/:orderId',
		element: OrderDetailPage,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.ORDER_ADMIN],
	},
	{
		exact: true,
		path: '/watchtower/order/pos/:orderId',
		element: OrderDetailPage,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.ORDER_ADMIN],
		props: {
			isPos: true,
		},
	},
	{
		exact: true,
		path: '/watchtower/promo',
		element: WatchTowerPromo,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.PROMO_ADMIN],
	},
	{
		exact: true,
		path: '/watchtower/promo/detail/:jobId',
		element: JobDetailPage,
		roles: [USER_ROLES.WATCHTOWER.ADMIN, USER_ROLES.WATCHTOWER.PROMO_ADMIN],
	},

	// Admin
	{
		exact: true,
		path: '/admin',
		element: Navigate,
		props: {
			to: '/admin/user-list',
			replace: true,
		},
	},
	{
		exact: true,
		path: '/admin/user-list',
		element: UserList,
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/user-list/new',
		element: UserForm,
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/user-list/edit',
		element: UserForm,
		props: {
			isEdit: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/version-uploader',
		element: VersionUploader,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/version-uploader/new',
		element: VersionUploaderForm,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/version-uploader/:id/edit',
		element: VersionUploaderForm,
		props: {
			isEdit: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/version-uploader/:id/detail',
		element: VersionUploaderForm,
		props: {
			isDetail: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/location-group',
		element: LocationGroup,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/location-group/new',
		element: LocationGroupForm,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/location-group/:id/edit',
		element: LocationGroupForm,
		props: {
			isEdit: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/location-group/:id/detail',
		element: LocationGroupForm,
		props: {
			isDetail: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/release-scheduler',
		element: ReleaseScheduler,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/release-scheduler/new',
		element: ReleaseSchedulerForm,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/release-scheduler/:id/edit',
		element: ReleaseSchedulerForm,
		props: {
			isEdit: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/release-scheduler/:id/detail',
		element: ReleaseSchedulerForm,
		props: {
			isDetail: true,
		},
		roles: Object.values(USER_ROLES.USER),
	},
	{
		exact: true,
		path: '/admin/version-settings/version-tracker',
		element: VersionTracker,
		props: {},
		roles: Object.values(USER_ROLES.USER),
	},

	// Sales Dashboard
	{
		exact: true,
		path: '/sales-dashboard/sales-transaction',
		element: FinalDashboard,
	},
	{
		exact: true,
		path: '/sales-dashboard/file-upload',
		element: TaxFileUpload,
	},
	{
		exact: true,
		path: '/sales-dashboard/fresto-x-pos',
		element: FrestoxESB,
	},
	{
		exact: true,
		path: '/sales-dashboard/platform-settlement',
		element: PlatformSettlement,
	},
	{
		exact: true,
		path: '/sales-dashboard/others',
		element: OthersSettlement,
	},
	{
		exact: true,
		path: '/sales-dashboard/checker',
		element: Checker,
	},

	// POS Settings
	{
		exact: true,
		path: '/pos-settings/payment',
		element: Payment,
	},
	{
		exact: true,
		path: '/pos-settings/payment/new',
		element: PaymentForm,
	},
	{
		exact: true,
		path: '/pos-settings/payment/:id/edit',
		element: PaymentForm,
		props: {
			isEdit: true,
		},
	},
	{
		exact: true,
		path: '/pos-settings/payment/:id/detail',
		element: PaymentForm,
		props: {
			isDetail: true,
		},
	},
	{
		exact: true,
		path: '/pos-settings/sales-channel',
		element: SalesChannel,
	},
	{
		exact: true,
		path: '/pos-settings/sales-channel/new',
		element: SalesChannelForm,
	},
	{
		exact: true,
		path: '/pos-settings/sales-channel/:id/edit',
		element: SalesChannelForm,
		props: {
			isEdit: true,
		},
	},
	{
		exact: true,
		path: '/pos-settings/sales-channel/:id/detail',
		element: SalesChannelForm,
		props: {
			isDetail: true,
		},
	},
	{
		exact: true,
		path: '/pos-settings/platform-internal/',
		element: InternalPlatform,
	},
	{
		exact: true,
		path: '/pos-settings/platform-internal/:new',
		element: InternalPlatformForm,
	},
	{
		exact: true,
		path: '/pos-settings/platform-internal/:id/edit',
		element: InternalPlatformForm,
	},
	{
		exact: true,
		path: '/pos-settings/outlet-internal',
		element: OutletActivation,
		props: {
			platformType: 'internal',
		},
	},
	{
		exact: true,
		path: '/internal-ordering/menu-creator',
		element: MenuCreator,
	},
	{
		exact: true,
		path: '/internal-ordering/menu-creator/new',
		element: MenuCreatorForm,
	},
	{
		exact: true,
		path: '/internal-ordering/menu-creator/edit/:menuCombinationId',
		element: MenuCreatorForm,
		props: {
			isEdit: true,
		},
	},
	{
		exact: true,
		path: '/internal-ordering/order-creator',
		element: OrderCreator,
	},
	{
		exact: true,
		path: '/internal-ordering/order-creator/campaign-detail/:campaignId',
		element: CampaignDetail,
	},
	{
		exact: true,
		path: '/internal-ordering/order-creator/campaign-detail/:campaignId/new',
		element: CampaignRequest,
	},
	{
		exact: true,
		path: '/internal-ordering/order-creator/campaign-detail/:campaignId/:requestId/edit',
		element: CampaignRequest,
		props: {
			isEdit: true,
		},
	},
	{
		exact: true,
		path: '/internal-ordering/order-creator/campaign-detail/:campaignId/:requestId/detail',
		element: CampaignRequest,
		props: {
			isDetail: true,
		},
	},


	// Misc
	{
		exact: true,
		path: '/',
		element: Home,
	},
	{
		path: '*',
		element: Navigate,
		props: {
			to: '/',
			replace: true,
		},
	},
];