import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';
import {Input, Modal, Typography} from 'antd';

const NewCampaignModal = ({open, onSave, loading, onClose, isEdit, campaignData, campaignType}) => {
	const locale = isEdit
		? localization.InternalOrdering.OrderCreator.EditCampaignModal
		: localization.InternalOrdering.OrderCreator.NewCampaignModal;

	const [inputVal, setInputVal] = useState('');

	useEffect(() => {
		if (open) setInputVal(campaignData?.name || '');
	}, [open]);

	return (
		<Modal
			closable={false}
			open={open}
			centered
			width={396}
			destroyOnClose
			onCancel={() => !loading && onClose()}
			okText={locale.okText}
			onOk={() => onSave(inputVal, campaignData)}
			cancelText={locale.cancelText}
			okButtonProps={{
				disabled: !inputVal.length,
				loading: loading,
			}}
			cancelButtonProps={{
				loading: loading,
			}}
		>
			<div>
				<Typography.Title level={5}>{locale.title.replace('{{campaignType}}', campaignType)}</Typography.Title>
				<div>{locale.subtitle}</div>
				<Input
					disabled={loading}
					value={inputVal}
					onChange={e => setInputVal(e.target.value)}
					className='mt-4'
					placeholder={locale.inputPlaceholder} />
			</div>
		</Modal>
	);
};

NewCampaignModal.defaultProps = {
	open: false,
	onSave: () => null,
	loading: false,
	onClose: () => null,
	isEdit: false,
	campaignData: {},
	campaignType: '',
};

NewCampaignModal.propTypes = {
	open: PropTypes.bool,
	onSave: PropTypes.func,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	isEdit: PropTypes.bool,
	campaignData: PropTypes.object,
	campaignType: PropTypes.string,
};

export default NewCampaignModal;