import React, {useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Select, Spin, Typography} from 'antd';
import './Select.less';

import debounce from 'lodash/debounce';

import {handleErrorFetch} from 'utils/utils';
import {getPosMenus} from 'utils/request/mapping';
import {getMasterlistData} from 'utils/request/masterlist';
import {MASTERLIST_TYPE} from 'utils/constants';

const DebounceSelect = ({debounceTimeout = 800, isReminder, ...props}) => {
	const {posId} = useParams();

	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const fetchRef = useRef(0);
	const debounceFetcher = useMemo(() => {
		const loadOptions = async value => {
			try {
				if (!value) return;
				fetchRef.current += 1;
				const fetchId = fetchRef.current;
				setOptions([]);
				setFetching(true);

				let tempOptions = [];

				if (isReminder) {
					const response = await getMasterlistData({
						limit: 20,
						search: {
							name: value,
							isActive: true,
						},
					}, MASTERLIST_TYPE.REMINDER);

					tempOptions = response.data.rows;
				} else {
					const searchPayload = {
						limit: 20,
						posId,
					};

					if (value) searchPayload.searchString = value;

					const response = await getPosMenus(searchPayload);
					tempOptions = response.data;

				}

				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(tempOptions);

			} catch (error) {
				handleErrorFetch(error);
			} finally {
				setFetching(false);
			}
		};

		return debounce(loadOptions, debounceTimeout);
	}, [debounceTimeout]);

	const getLabel = menu => {
		switch (isReminder) {
		case false:
			return menu.displayName;
		case true:
			return `${menu.id} - ${menu.name}`;
		default:
			return '';
		}
	};

	return (
		<div className='DebounceSelect flex-1'>
			<Select
				showSearch
				filterOption={false}
				allowClear
				onSearch={debounceFetcher}
				notFoundContent={
					fetching
						? (
							<div className='flex items-center justify-center' >
								<Spin />
							</div>
						)
						: <div>No Data</div>
				}
				{...props}
			>
				{options.map(menu => (
					<Select.Option
						key={menu.id}
						value={getLabel(menu)}
					>
						<Typography.Text
							ellipsis={{tooltip: getLabel(menu)}}
						>{getLabel(menu)}</Typography.Text>
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

DebounceSelect.defaultProps = {
	debounceTimeout: 800,
	isReminder: false,
};

DebounceSelect.propTypes = {
	debounceTimeout: PropTypes.number,
	isReminder: PropTypes.bool,
};

export default DebounceSelect;