import React from 'react';

import {Button, Dropdown} from 'antd';
import {FormOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';
import OrderTemplateTable from './OrderTemplateTable';

import {INTERNAL_ORDERING_TYPE} from 'utils/constants';

import localization from 'localization';
const locale = localization.InternalOrdering.MenuCreator;

const MenuCreator = () => {
	const navigate = useNavigate();

	const dropdownItems = INTERNAL_ORDERING_TYPE.map(type => {
		return {
			...type,
			onClick: () => navigate('/internal-ordering/menu-creator/new', {
				state: {
					requestType: type.key,
				},
			}),
		};
	});

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={[
					<Dropdown
						trigger={['click']}
						menu={{items: dropdownItems}}
						key='create'>
						<Button
							icon={<FormOutlined />}
							type='primary'
						>{locale.createNew}
						</Button>
					</Dropdown>,
				]}
			/>
			<OrderTemplateTable />
		</div>
	);
};

export default MenuCreator;