import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {numberFormat} from 'utils/utils';
import {AVAILABLE_POS, PARENT_MAPPING_OPTIONS} from 'utils/constants';
import {getValueByPath, setValueByPath} from 'utils/mapping';

import DebounceSelect from './DebounceSelect';

import cloneDeep from 'lodash/cloneDeep';
import unset from 'lodash/unset';

import {Button, Divider, Form, InputNumber, Select, Typography} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageMapping;

const MenuItemComponent = ({
	section, menuItem = {}, optionGroupId,
	ignored, getMappingValue, showDivider,
	form, isBundle, isEdit,
}) => {
	const {posId} = useParams();
	const [entrySection, setEntrySection] = useState([]);

	const isOdoo = posId == AVAILABLE_POS.ODOO.id;
	const itemLabel = menuItem.menuLabel || menuItem.optionGroupLabel;
	const mappingEntry = section === PARENT_MAPPING_OPTIONS.MENU ? 'items' : 'childMenus';

	const updateEntrySection = () => {
		if (section == PARENT_MAPPING_OPTIONS.MENU) {
			setEntrySection([PARENT_MAPPING_OPTIONS.MENU, mappingEntry]);
		}

		if (section == PARENT_MAPPING_OPTIONS.OPTION_GROUP) {
			setEntrySection([PARENT_MAPPING_OPTIONS.OPTION_GROUP, optionGroupId, mappingEntry, menuItem?.menuId]);
		}

		if (section == PARENT_MAPPING_OPTIONS.COMPLEX_RULE) {
			const complexEntry = menuItem.map(item => item.menuId.toString());
			setEntrySection([PARENT_MAPPING_OPTIONS.COMPLEX_RULE, 'items', ...complexEntry]);
		}
	};

	const getMenuLabel = () => {
		// Used for error message
		if (section == PARENT_MAPPING_OPTIONS.COMPLEX_RULE) {
			return `${menuItem[0].menuLabel} - ${menuItem[1].menuLabel}`;
		} else {
			return menuItem?.menuLabel;
		}
	};

	const extractBOMCode = str => {
		const match = str.match(/\[BOM:\s*(.*?)\]/);
		return match ? match[1] : null;
	};

	useEffect(() => {
		updateEntrySection();
	}, []);

	return (
		<>
			{/* // Left Section (Menu Label) */}
			<div className='flex flex-col lg:flex-row lg:gap-10 mr-12'>
				<div className='flex flex-shrink-0 w-80'>
					<div className='flex flex-col gap-2'>
						<Typography.Paragraph>
							<ul>
								{
									section === PARENT_MAPPING_OPTIONS.COMPLEX_RULE
										? menuItem.map?.(item => (
											<li key={item.menuId}>{item.menuLabel}</li>
										))
										: <li>{itemLabel}</li>
								}
							</ul>
						</Typography.Paragraph>
					</div>
				</div>

				{/* Right Section (Mapping) */}
				{
					ignored
						? (
							<div className='flex flex-1 flex-col gap-4'>
								<Select
									disabled={true}
									className='w-full'
									placeholder={locale.ignorePlaceholder}
								/>
							</div>
						) : (
							<Form.List
								name={entrySection}
							>
								{(fields, {add, remove}) => (
									<div className='flex flex-1 flex-col gap-4'>
										{
											fields.map((field, index) => (
												<div
													key={index}
													className='flex flex-wrap'>
													<div className='flex flex-1 items-center gap-3'>

														{/* Select Section */}
														<div className='flex flex-1'>
															<div className='bg-antd-netural-2 border border-antd-netural-5 rounded-l-sm px-3 flex items-center capitalize'>
																{getValueByPath(getMappingValue(), [...entrySection, field.name])?.menuType}
															</div>
															<Form.Item
																className='flex-1'
																{...field}
																noStyle
																name={[field.name, 'displayName']}
																normalize={e => e || null}
																messageVariables={{
																	order: index + 1,
																	menuLabel: getMenuLabel(),
																}}
																rules={[{
																	required: true,
																	message: '${menuLabel} missing mapping [${order}]',
																	warningOnly: isOdoo ? true : false,
																}]}
															>
																<DebounceSelect
																	onChange={e => {
																		const mappingTargetId = e ? e.split(' - ')[0] : '';
																		const mappingMenuCode = e ? e.split(' - ')[1] : '';
																		const menuLabelTemp = e ? e.split(' - ')[2] : '';
																		const lastItem = e ? e.split(' - ').pop() : null;
																		const tempFormVal = cloneDeep(getMappingValue());
																		const isReminder = getValueByPath(getMappingValue(), [...entrySection, field.name])?.menuType == 'reminder';

																		setValueByPath(
																			tempFormVal,
																			[...entrySection, field.name, 'menuId'],
																			mappingTargetId,
																		);

																		if (!isReminder) {
																			// If not reminder

																			const mappingMenuCodeVal = mappingMenuCode && mappingMenuCode !== 'NO_CODE'
																				? mappingMenuCode
																				: null;

																			setValueByPath(
																				tempFormVal,
																				[...entrySection, field.name, 'menuCode'],
																				mappingMenuCodeVal,
																			);
																			if (lastItem && lastItem.includes('[BOM:')) {
																				setValueByPath(
																					tempFormVal,
																					[...entrySection, field.name, 'bomName'],
																					extractBOMCode(lastItem),
																				);
																			} else {
																				unset(
																					tempFormVal,
																					[...entrySection, field.name, 'bomName'],
																				);
																			}
																		}

																		setValueByPath(
																			tempFormVal,
																			[...entrySection, field.name, 'menuLabel'],
																			e ? e.split(' - ')[isReminder ? 1 : 2] : null,
																		);

																		form.setFieldsValue({
																			[section]: tempFormVal[section],
																		});
																	}}
																	isReminder={
																		getValueByPath(getMappingValue(), [...entrySection, field.name])?.menuType == 'reminder'
																	}
																	className='w-full'
																/>
															</Form.Item>
														</div>
														{
															['parent', 'child'].some(item => item === getValueByPath(getMappingValue(), [...entrySection, field.name, 'menuType']))
																? (
																	<div
																		className='w-40'
																	>
																		{
																			[undefined, null].some(item => item === getValueByPath(getMappingValue(), [...entrySection, field.name, 'menuPrice']))
																				? (
																					<Button
																						onClick={() => {
																							const tempFormVal = cloneDeep(getMappingValue());

																							setValueByPath(
																								tempFormVal,
																								[...entrySection, field.name, 'menuPrice'],
																								'',
																							);

																							form.setFieldsValue({
																								[section]: tempFormVal[section],
																							});
																						}}
																						className='w-full'>
																						Override Price
																					</Button>
																				)
																				: (
																					<Form.Item
																						key={index}
																						noStyle
																						name={[field.name, 'menuPrice']}
																					>
																						{/* <Input /> */}
																						<InputNumber
																							disabled={isBundle && !isEdit}
																							parser={value => value.replace(/[,.]/g, '')}
																							formatter={value => {
																								if (value) {
																									return numberFormat(value);
																								}
																								return value;
																							}}
																							placeholder='Input price'
																							controls={false}
																							addonBefore='Rp'
																						/>
																					</Form.Item>
																				)
																		}
																	</div>
																)
																: null
														}
														{
															index !== 0 && (
																<Button
																	onClick={() => remove(field.name)}
																	danger
																	icon={<DeleteOutlined />}
																/>
															)
														}
													</div>
												</div>
											))
										}
										{
											!ignored && (
												<div className='flex gap-2'>
													{
														isOdoo && (
															<div
																onClick={() => {
																	const outputTemp = {
																		menuType: 'reminder',
																		menuId: null,
																		menuLabel: null,
																		displayName: null,
																		menuCode: null,
																	};

																	if (isBundle) outputTemp.menuPrice = 0;

																	add(outputTemp);
																}}
																className='flex items-center gap-2 text-antd-blue-6 cursor-pointer max-w-max'>
																<PlusOutlined />
																{locale.addReminder}
															</div>
														)
													}
													<div
														onClick={() => {
															const outputTemp = {
																menuType: 'child',
																menuId: null,
																menuLabel: null,
																displayName: null,
																menuCode: null,
															};

															if (isBundle) outputTemp.menuPrice = 0;

															add(outputTemp);
														}}
														className='flex items-center gap-2 text-antd-blue-6 cursor-pointer max-w-max'>
														<PlusOutlined />
														{locale.addChild}
													</div>
												</div>
											)
										}
									</div>
								)}
							</Form.List>
						)}
			</div>
			{
				showDivider && <Divider dashed />
			}
		</>
	);
};

MenuItemComponent.defaultProps = {
	section: null,
	menuItem: {},
	optionGroupId: undefined,
	ignored: false,
	getMappingValue: () => null,
	form: () => null,
	showDivider: false,
	isBundle: false,
	isEdit: false,
};

MenuItemComponent.propTypes = {
	section: PropTypes.string,
	menuItem: PropTypes.object,
	optionGroupId: PropTypes.number,
	ignored: PropTypes.bool,
	getMappingValue: PropTypes.func,
	form: PropTypes.func,
	showDivider: PropTypes.bool,
	isBundle: PropTypes.bool,
	isEdit: PropTypes.bool,
};

export default MenuItemComponent;