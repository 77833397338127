import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {createMasterlistItem, editMasterlistItem} from 'utils/request/masterlist';
import {IMAGE_SIZE, CLOUDINARY_DIR, MENU_STATION} from 'utils/constants';
import {uploadImage} from 'utils/cloudinary';
import {getStationLabel, handleErrorFetch, inputNumberCurrencyFormatter, isURL} from 'utils/utils';

import {Button, Form, Input, InputNumber, Modal, notification, Radio, Select, Upload, Typography} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
const {Text} = Typography;
const {TextArea} = Input;


import localization from 'localization';
const locale = localization.MasterlistPage.MasterlistForm.MenuForm;
const actionLocale = localization.MasterlistPage.MasterlistForm.action;

const MenuForm = ({isEdit, itemRecord, isChildMenu}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const isFreeItemWatcher = Form.useWatch('isFreeItem', form);

	const [isLoading, setIsLoading] = useState(false);
	const [fileList, setFileList] = useState([]);

	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);

	const handleSubmit = async e => {
		try {
			setIsLoading(true);
			const {image} = e;
			if (image?.file) {
				if (isURL(image.file)) {
					e.imageUrl = image.file;
				} else if (image.file.status !== 'removed') {
					const response = await uploadImage(image.file, CLOUDINARY_DIR.MENU_IMAGE);
					e.imageUrl = response.secure_url || response.url;
				}
			}
			if (!fileList.length) e.imageUrl = null;

			delete e.image;
			Object.keys(e).forEach(key => e[key] === undefined ? delete e[key] : {});

			if (e.isActive === 1) {
				e.isActive = true;
			} else {
				e.isActive = false;
			}

			if (e.isFreeItem === 1) {
				e.isFreeItem = true;
			} else {
				e.isFreeItem = false;
			}

			const fetchFn = isEdit ? editMasterlistItem : createMasterlistItem;
			if (isEdit) e.menuId = itemRecord.id;
			e.isChildMenu = isChildMenu;
			const response = await fetchFn(e, 'master-menu');
			if (response.success) {
				const notificationLocale = isEdit ? actionLocale.EditSuccess : actionLocale.CreateSuccess;
				navigate(`/masterlist/${isChildMenu ? 'child-' : ''}menu`);
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description.replace('{{type}}', 'menu'),
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = info => {
		if (info.file.size && info.file?.size > IMAGE_SIZE.MAX_IMAGE_SIZE) {
			notification.open({
				message: locale.imageSizeErrorMessage,
				description: locale.imageSizeErrorDescription,
				type: 'warning',
			});
			return;
		}
		let fileListTemp = [...info.fileList];

		fileListTemp = fileListTemp.map(file => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url;
			}
			return file;
		});

		setFileList(fileListTemp);
	};

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	useEffect(() => {
		if (isEdit) {
			form.setFieldsValue({
				isActive: itemRecord.isActive ? 1 : 0,
				menuLabel: itemRecord.menuLabel,
				kdsName: itemRecord.kdsName,
				tags: itemRecord.tags || [],
				menuCode: itemRecord.menuCode,
				description: itemRecord.description,
				price: String(itemRecord.price),
				image: {file: itemRecord.imageUrl},
				isFreeItem: itemRecord.isFreeItem ? 1 : 0,
				station: itemRecord.station,
			});

			if (itemRecord.imageUrl && isURL(itemRecord.imageUrl)) {
				setFileList([{
					uid: itemRecord.id,
					name: itemRecord?.imageUrl?.split('/')?.pop(),
					url: itemRecord.imageUrl,
				}]);
			}
		}
	}, []);

	useEffect(() => {
		// Disabled price input and set it to 0 if its free item
		if (isFreeItemWatcher === 1) {
			form.setFieldsValue({
				price: 0,
			});
		}
	}, [isFreeItemWatcher]);

	return (
		<Form
			form={form}
			layout="vertical"
			name="menuForm"
			scrollToFirstError
			onFinish={e => !isLoading && handleSubmit(e)}
			onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
			requiredMark={false}
			initialValues={{
				isActive: 1,
				isFreeItem: 0,
				station: '',
			}}
		>
			<Modal
				visible={previewVisible}
				onCancel={() => setPreviewVisible(false)}
				footer={null}
			>
				<img
					alt="example"
					style={{width: '100%'}}
					src={previewImage} />
			</Modal>
			<Form.Item
				label={isChildMenu ? locale.childMenuLabel : locale.menuLabel}
				name="menuLabel"
				rules={[{required: true, message: locale.menuLabelRequired}]}
			>
				<Input
					className='w-96'
					placeholder={locale.menuLabelPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.kdsNameLabel}
				name="kdsName"
				rules={[{required: true, message: locale.kdsNameRequired}]}
			>
				<Input
					className='w-96'
					placeholder={locale.kdsNamePlaceholder}
					showCount
					maxLength={30}
				/>
			</Form.Item>
			<Form.Item
				label={locale.menuCode}
				name="menuCode"
			>
				<Input
					className='w-96'
					placeholder={locale.menuCodePlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.stationLabel}
				name="station"
			>
				<Select
					className='w-96'
					allowClear
				>
					{Object.values(MENU_STATION).map(station => (
						<Select.Option
							key={station}
							label={station}
							value={station}
						>
							{getStationLabel(station)}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			{
				!isChildMenu && (
					<Form.Item
						label={locale.menuDescription}
						name="description"
					>
						<TextArea
							className='w-96'
							showCount
							maxLength={300}
							placeholder={locale.menuDescriptionPlaceholder}
						/>
					</Form.Item>
				)
			}
			<Form.Item
				label={locale.menuPrice}
				name="price"
				rules={[{required: true, message: locale.menuPriceRequired}]}
			>
				<InputNumber
					disabled={isFreeItemWatcher === 1}
					formatter={inputNumberCurrencyFormatter}
					addonBefore='Rp'
					type='tel'
					className='w-96'
					placeholder={locale.menuPricePlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.tagsLabel}
				name="tags">
				<Select
					placeholder={locale.tagsLabelPlaceholder}
					className='w-96'
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			</Form.Item>
			<Form.Item
				label={locale.statusLabel}
				name="isActive">
				<Radio.Group>
					<Radio
						key='active'
						value={1}>Yes</Radio>
					<Radio
						key='inactive'
						value={0}>No</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item
				label={locale.freeItemLabel}
				name="isFreeItem">
				<Radio.Group>
					<Radio
						key='yes'
						value={1}>Yes</Radio>
					<Radio
						key='no'
						value={0}>No</Radio>
				</Radio.Group>
			</Form.Item>
			{
				!isChildMenu && (
					<Form.Item
						className='max-w-lg'
						label={locale.imageLabel}
						name="image">
						<Upload
							accept="image/*"
							onChange={handleChange}
							multiple={false}
							maxCount={1}
							fileList={fileList}
							beforeUpload={() => false}
							listType="picture"
							onPreview={handlePreview}
						>
							<Button icon={<UploadOutlined />}>{locale.imageUploadBtn}</Button>
							<Text
								className='block'
								type="secondary">{locale.imageLabelInfo}</Text>
						</Upload>
					</Form.Item>
				)
			}
			<Form.Item>
				<div className='flex gap-2 justify-end'>
					<Button
						onClick={() => navigate(-1)}
						disabled={isLoading}>
						{actionLocale.cancel}
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}>
						{actionLocale.submit}
					</Button>
				</div>
			</Form.Item>
		</Form>
	);
};

MenuForm.defaultProps = {
	isEdit: false,
	itemRecord: {},
	isChildMenu: false,
};

MenuForm.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
	isChildMenu: PropTypes.bool,
};

export default MenuForm;