import ProTable from '@ant-design/pro-table';
import {Divider, Typography} from 'antd';
import localization from 'localization';
import {capitalize, isEmpty} from 'lodash';
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {getCampaignList} from 'utils/request/internalOrdering';
import {dateFormat, handleErrorFetch, sortDirectionConverter} from 'utils/utils';

const CampaignListTable = ({editCampaignConfirmation, refetchTableRef}) => {
	const tableRef = useRef(null);
	const locale = localization.InternalOrdering.OrderCreator;

	const columns = [
		{
			title: locale.Table.type,
			width: 131,
			key: 'type',
			dataIndex: 'type',
			search: false,
			render: type => capitalize(type),
			filterMultiple: false,
			filters: [
				{
					text: 'KOL',
					value: 'KOL',
				},
				{
					text: 'Bulk',
					value: 'bulk',
				},
				{
					text: 'Replacement',
					value: 'replacement',
				},
			],
		},
		{
			title: locale.Table.campaignId,
			width: 200,
			key: 'id',
			dataIndex: 'id',
			render: id => (
				<Typography.Text
					copyable
				>{id?.toUpperCase?.()}</Typography.Text>
			),
		},
		{
			title: locale.Table.campaignName,
			width: 222,
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: locale.Table.orderCount,
			width: 175,
			dataIndex: 'orderCount',
			key: 'orderCount',
			search: false,
			render: orderCount => `${orderCount} Request`,
		},
		{
			title: locale.Table.createdBy,
			width: 155,
			key: 'createdBy',
			dataIndex: ['creator', 'username'],
		},
		{
			title: locale.Table.createdAt,
			width: 195,
			key: 'createdAt',
			dataIndex: 'createdAt',
			sorter: true,
			defaultSortOrder: 'descend',
			search: false,
			render: createdAt => dateFormat(createdAt),
		},
		{
			title: locale.Table.editedBy,
			width: 155,
			key: 'updatedBy',
			search: false,
			dataIndex: 'updatedBy',
		},
		{
			title: locale.Table.editedAt,
			width: 195,
			key: 'updatedAt',
			sorter: true,
			dataIndex: 'updatedAt',
			search: false,
			render: updatedAt => updatedAt ? dateFormat(updatedAt) : '-',
		},
		{
			title: locale.Table.action,
			width: 197,
			key: 'action',
			search: false,
			fixed: 'right',
			render: (_, record) => (
				<div className='flex gap-2 justify-center items-center'>
					<a
						className='text-antd-blue-6'
						onClick={() => editCampaignConfirmation(record)}
					>{locale.Table.edit}</a>
					<Divider
						className='m-0'
						type='vertical' />
					<a
						className='text-antd-blue-6'
					>{locale.Table.detail}</a>
					<Divider
						className='m-0'
						type='vertical' />
					<a
						// onClick={() => deleteCofirmation(record.id, record.name)}
						className='text-antd-red-6'
					>{locale.Table.delete}</a>
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const sortOrderKey = isEmpty(sorter) ? 'descend' : Object.values?.(sorter)?.[0];
			const sortByKey = isEmpty(sorter) ? 'createdAt' : Object.keys?.(sorter)?.[0];

			const payload = {
				limit: params?.pageSize,
				page: params?.current - 1,
				sortBy: sortByKey,
				sortOrder: sortDirectionConverter(sortOrderKey),
				search: {
					id: params?.id?.toLowerCase?.() || null,
					name: params?.name || null,
					createdBy: params?.createdBy || null,
					type: filters?.type?.[0] || null,
				},
			};
			const response = await getCampaignList(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		refetchTableRef.current = tableRef?.current?.reload;
	}, []);

	return (
		<div>
			<ProTable
				actionRef={tableRef}
				className="mt-4 ResetSearchTableMargin px-6"
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				onReset={() => tableRef?.current?.reset()}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
};

CampaignListTable.defaultProps = {
	editCampaignConfirmation: () => null,
	refetchTableRef: null,
};

CampaignListTable.propTypes = {
	editCampaignConfirmation: PropTypes.func,
	refetchTableRef: PropTypes.object,
};

export default CampaignListTable;