import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';

import LocalPageHeader from 'components/LocalPageHeader';
import {Button, Modal, notification, Spin, Tabs} from 'antd';
import MenuTemplateWrapper from 'pages/SalesChannel/MegaMenuTemplateDetail/MenuTemplateWrapper';
import {calculateCartMenuprice, cartCompressHandler, handleErrorFetch} from 'utils/utils';
import MenuTemplateCart from 'components/MenuTemplateCart';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import SaveConfirmationModal from './SaveConfirmationModal';
import {createNewOrderTemplate, editOrderTemplate, getActiveMenuTemplate, getOrderTemplates} from 'utils/request/internalOrdering';
import {isEmpty} from 'lodash';

const MenuCreatorForm = ({isEdit}) => {
	const locale = localization.InternalOrdering.MenuCreatorForm;
	const navigate = useNavigate();
	const location = useLocation();
	const {menuCombinationId} = useParams();

	const requestType = location?.state?.requestType || null;

	const [loadingData, setLoadingData] = useState(true);
	const [cart, setCart] = useState([]);
	const [megaMenuTemplateData, setMegaMenuTemplateData] = useState({});
	const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);
	const [menuCombinationData, setMenuCombinationData] = useState({});

	const brandTabs = megaMenuTemplateData?.groupings || megaMenuTemplateData?.items || [];

	const cartSetter = item => {
		const cartTemp = JSON.parse(JSON.stringify(cart));
		const compressedCart = cartCompressHandler(cartTemp.concat(item));
		setCart(compressedCart);
	};

	const calcualteTotalCart = () => {
		let cartPrice = 0;
		cart.forEach(item => cartPrice+= calculateCartMenuprice(item, item.menuItem.quantity));
		return cartPrice;
	};

	const handleSaveMenuCombination = async menuCombinationName => {
		try {
			setLoadingSave(true);

			const payload = {
				menuTemplateId: megaMenuTemplateData.id,
				name: menuCombinationName,
				subtotal: calcualteTotalCart(),
				items: cart.map(cartItem => {
					return {
						...cartItem.menuItem,
						mappingHash: cartItem.mappingHash,
					};
				}),
			};

			if (isEdit) payload.id = menuCombinationData.id;
			else payload.type = requestType;

			const callFn = isEdit ? editOrderTemplate : createNewOrderTemplate;

			const response = await callFn(payload);

			if (response.success) {
				navigate('/internal-ordering/menu-creator');
				notification.success({
					message: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].message,
					description: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].description,
				});
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].message,
				description: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].description,
			});
		} finally {
			setLoadingSave(false);
			setSaveConfirmationModalOpen(false);
		}
	};

	const getMenuTemplate = async () => {
		try {
			let megaMenuTemplateDataTemp = null;
			const response = await getActiveMenuTemplate({type: requestType});
			if (response.success && !isEmpty(response.data)) {
				megaMenuTemplateDataTemp = response.data;
				setMegaMenuTemplateData(megaMenuTemplateDataTemp);
			} else throw {};
			if (menuCombinationId) {
				const menuCombinationResponse = await getOrderTemplates({id: menuCombinationId});
				if (menuCombinationResponse.success && menuCombinationResponse.data.count) {
					const menuCombinationDataTemp = menuCombinationResponse.data.rows[0];
					setMenuCombinationData(menuCombinationDataTemp);
					const uniqueMenuStructure = Object.values(megaMenuTemplateDataTemp?.groupings)
						.reduce((acc, obj) => {
							obj.MenuTemplate.body.forEach(group => {
								group.menus.forEach(menu => {
									if (!acc.some(existingMenu => existingMenu.mappingHash === menu.mappingHash)) {
										acc.push(menu);
									}
								});
							});
							return acc;
						}, []);

					const validCart = uniqueMenuStructure
						.map(sourceItem => {
							// Find the corresponding item based on mappingHash
							const matchedItem = menuCombinationDataTemp.items.find(item => item.mappingHash === sourceItem.mappingHash);

							if (matchedItem) {
								// Merge the matched item with the source item
								return {
									mappingHash: sourceItem.mappingHash,
									dataOrigin: sourceItem,
									menuItem: matchedItem,
								};
							}

							// If no match found, return null or an empty object based on requirements
							return null;
						})
						.filter(result => result !== null); // Filter out any null results

					setCart(validCart);
				} else throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingData(false);
		}
	};

	useEffect(() => {
		if (!requestType) return navigate('/internal-ordering/menu-creator');
		getMenuTemplate();
	}, []);

	return (
		<>
			<SaveConfirmationModal
				open={saveConfirmationModalOpen}
				onSave={handleSaveMenuCombination}
				loadingSave={false}
				onClose={() => !loadingSave && setSaveConfirmationModalOpen(false)}
				isEdit={isEdit}
				currentMenuCombinationName={menuCombinationData?.name}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={isEdit
						? `${menuCombinationData?.name} (${requestType})`
						: locale.title.replace('{{type}}', requestType)}
					subtitle={megaMenuTemplateData?.id ? `Temp. ID: ${megaMenuTemplateData?.id}` : null}
				/>
				<div className='flex flex-col'>
					{
						loadingData
							? (
								<Spin size='lg' />
							)
							: (
								<>
									<div>
										{
											brandTabs.length
												? (
													<Tabs
														tabBarStyle={{paddingInline: '24px'}}
													>
														{
															brandTabs.map(menuTemplate => (
																<Tabs.TabPane
																	key={menuTemplate.brandId}
																	tab={menuTemplate.Brand.label}>
																	<MenuTemplateWrapper
																		menuTemplateId={menuTemplate.menuTemplateId}
																		menuTemplateGrouping={menuTemplate.MenuTemplate.body}
																		cart={cart}
																		setCart={item => cartSetter(item)}
																		isMenuCreator={true}
																	/>
																</Tabs.TabPane>
															))
														}
													</Tabs>
												)
												: null
										}
									</div>
									<div className='flex gap-2 justify-end px-6 pb-6'>
										<Button onClick={() => {
											Modal.confirm({
												icon: <ExclamationCircleOutlined />,
												centered: true,
												title: locale.CancelConfirmation.message,
												content: locale.CancelConfirmation.description,
												cancelText: locale.CancelConfirmation.cancelText,
												okText: locale.CancelConfirmation.okText,
												onOk: () => navigate('/internal-ordering/menu-creator'),
											});
										}}>{locale.cancel}</Button>
										<MenuTemplateCart
											cart={cart}
											setCart={setCart}
											customFooter={() => (
												<Button
													disabled={!cart.length}
													onClick={() => setSaveConfirmationModalOpen(true)}
													type='primary'
													className='w-full'
												>{isEdit ? locale.save : locale.createMenu}</Button>
											)}
										/>
									</div>
								</>
							)
					}
				</div>
			</div>
		</>
	);
};

MenuCreatorForm.defaultProps = {
	isEdit: false,
};

MenuCreatorForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default MenuCreatorForm;